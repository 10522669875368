/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

const appName = null;

const error = {
  message: null,
};

const history = null;

const loggedInUser = {
  entity: null,
  id: null,
  username: null,
  status: null,
};

const notification = {
  entities: [],
};

const roots = {
  items: [],
  units: [],
  siUnitSystem: null,
  counts: new Map(),
  status: null,
  classifications: [],
  activeApp: {
    id: '',
    attributes: { name: '' },
    system: {},
  },
  plotJson: null,
  plotJsonMap: null,
  materialDetails: [],
  materialDatasheet: [],
  compareDetails: [],
  bbCompareDetails: [],
  landingBB: [],
  resultSummary: [],
  bbFooter: [],
  bbTopBar: [],
  bbWaffleMenu: [],
  lcaGeneric: [],
  bbPageForDialog: [],
  bbPageForComponent: new Map(),
  appTitle: [],
  contentDefinitions: {},
  catalogs: [],
  searchCatalog: null,
  compareCatalog: [],
  tableColumns: [],
  updateSearchCriteria: null,
  ranges: null,
  texts: [],
  preferences: null,
  language: null,
  derivedContentDefinitions: [],
  isImportModalOpened: false,
  canImport: false,
  canAccess: false,
  perms: [],
  uicheckRun: false,
  filterData: { new: true, featfilter: [] },
  previousFilterData: {},
  storedTags: [],
  scatterData: [],
  CAEPlots: [],
  CAESelectedDiagram: null,
  hasMaterials: false,
  supportedApplicationMenus: {},
  offset: 0,
  hasMore: true,
  firstLoad: true,
  pageSize: 30,
  appContent: null,
  config: {},
  entityView: 'tiles',
  defaultViewShown: false,
  pdfDownloadWaiting: new Set(),
  availLanguages: {},
  userUnitSystems: {},
  unitSystem: null,
  filterDataStatus: 'CURRENT',
  rangeStatus: 'LOADING',
  polarData: null,
  polarLoadingStatus: 'LOADING',
  polarCatalog: [],
  appsPerms: {},
  impactAssessmentData: {},
  activeId: null,
  activeName: null,
  exportCompare: {},
  hasSearchCriteria: false,
  track: new Map(),
  languageOverride: null,
  favoriteList: [],
  favoritesView: false,
  caeDownloadPreview: null,
  similarId: null,
  similarProfile: null,
  notifications: [],
  totalCount: 0,
  datasheetStatus: 'LOADING',
};

const localNotifications = [];

const selectedEntities = [];

const viewedEntity = null;

export default {
  appName,
  error,
  history,
  loggedInUser,
  roots,
  selectedEntities,
  viewedEntity,
  notification,
  localNotifications,
};
