import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { processBuildingBlock } from '../processBuildingBlock';
import { Panel, PanelType } from '@fluentui/react';

const mapStateToProps = state => {
  return {
    config: state.roots.config,
    bbWaffleMenu: state.roots.bbWaffleMenu,
    ranges: state.roots.ranges,
    canAccess: state.roots.canAccess,
    language: state.roots.language,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actions, dispatch);
};

function WaffleMenuContainer({ config, bbWaffleMenu, getBBWaffleMenu, ranges, canAccess, isOpen, dismissPanel, language }) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);

  const onTabClick = useCallback(
    (id, item) => {
      setActiveTabMap(new Map(activeTabMap.set(id, item)));
    },
    [activeTabMap]
  );

  useEffect(() => {
    if (config.wafflemenu && language) {
      getBBWaffleMenu(config.wafflemenu.page);
    }
  }, [config, getBBWaffleMenu, language]);

  const addStyle = useCallback(
    (id, style) => {
      if (!addedStyles.includes(id)) {
        var styleSheet = document.createElement('style');

        let styleText = '';
        if (style && style.length > 0) {
          for (let styleElement of style) {
            styleText += '.wafflemenu' + styleElement + ' ';
          }
          styleSheet.innerText = styleText;
          document.head.appendChild(styleSheet);
        }

        setAddedStyles(prevStyles => [...prevStyles, id]);
      }
    },
    [addedStyles]
  );

  const getActiveTab = useCallback(
    id => {
      return activeTabMap.get(id)?.props?.itemKey;
    },
    [activeTabMap]
  );

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys(oldCollapsedVkeys => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys(oldCollapsedVkeys =>
        oldCollapsedVkeys.filter(collapsedVkey => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    if (bbWaffleMenu) {
      for (let buildingBlock of bbWaffleMenu) {
        processBuildingBlock({
          buildingBlock,
          body: newBody,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          ranges,
        });
      }
    }

    setBody(newBody);
  }, [bbWaffleMenu, layoutMap, collapsedVkeys, canAccess, ranges, addStyle, getActiveTab, onTabClick]);

  if (config.wafflemenu) {
    return (
      <Panel isOpen={isOpen} type={PanelType.smallFixedNear} onDismiss={dismissPanel}>
        <div className="amdc-block wafflemenu">{body}</div>
      </Panel>
    );
  } else {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaffleMenuContainer);
