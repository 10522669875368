/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IconButton, Panel, PanelType } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { AdditionalServices } from '../AdditionalServices';
import { Content, HeaderBottomSection, HeaderSection, HeaderTopSection } from './styled';
import NavButton from '../NavButton';
import { useTopbar } from '../../context/useTopbar';

const navButtonStyles = {
  root: {
    flex: 1,
    padding: '8px 12px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    gap: '4px',
  },
  icon: {
    margin: '0px',
  },
  textContainer: {
    flexGrow: 'initial',
  },
};

const NOOP = () => {};

export const defaultOnRenderHeader = (props) => {
  const { homeButtonProps } = props;
  return (
    <>
      {!homeButtonProps?.isHidden && (
        <NavButton
          data-testid="MenuPanel-Button-Home"
          iconProps={{ iconName: 'unity_home' }}
          styles={navButtonStyles}
          href={`${homeButtonProps.url}`}
          className="ao-home-button"
          {...homeButtonProps}
        >
          Home
        </NavButton>
      )}
    </>
  );
};

export function WaffleMenuBase({
  additionalServicesProps,
  dashboardButtonProps,
  homeButtonProps,
  className,
  isOpen = false,
  items,
  onDismiss = NOOP,
  onRenderHeader = defaultOnRenderHeader,
  styles,
  onlineHelpUrl,
  panelProps,
}) {
  const { altairOneBaseUrl = '' } = useTopbar();

  return (
    <Panel
      {...panelProps}
      closeButtonAriaLabel="Close"
      className={className}
      data-testid="MenuPanel"
      isBlocking={panelProps?.isBlocking ?? false}
      isOpen={isOpen}
      onDismissed={onDismiss}
      hasCloseButton={false}
      styles={styles}
      type={panelProps?.type ?? PanelType.customNear}
      customWidth="320px"
      onRenderHeader={() => (
        <HeaderSection>
          <HeaderTopSection>
            <IconButton
              data-testid="MenuPanel-Button-Close"
              iconProps={{ iconName: 'ChromeClose' }}
              onClick={onDismiss}
              styles={{
                root: {
                  height: '16px',
                  width: '16px',
                },
              }}
            />
          </HeaderTopSection>
          <HeaderBottomSection>
            {onRenderHeader({ dashboardButtonProps, homeButtonProps, altairOneBaseUrl }, defaultOnRenderHeader)}
          </HeaderBottomSection>
        </HeaderSection>
      )}
    >
      <Content>
        {items}
        <AdditionalServices
          position="vertical"
          width="100%"
          altairOneBaseUrl={altairOneBaseUrl}
          onlineHelpUrl={onlineHelpUrl}
          {...additionalServicesProps}
        />
      </Content>
    </Panel>
  );
}

WaffleMenuBase.propTypes = {
  additionalServicesProps: PropTypes.any,
  dashboardButtonProps: PropTypes.shape({
    isHidden: PropTypes.bool,
  }),
  homeButtonProps: PropTypes.shape({
    isHidden: PropTypes.bool,
  }),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  items: PropTypes.node,
  onDismiss: PropTypes.func,
  styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onlineHelpUrl: PropTypes.string,
};
