/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { FontSizes } from '@fluentui/style-utilities';

export const getStyles = () => {
  return {
    root: [
      'aone-footer',
      {
        minHeight: 60,
        width: '100%',
        background: '#F4F4F4',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'wrap',
        position: 'relative',
      },
    ],
    brand: {
      height: 18,
      margin: '0px 16px',
    },
    badge: {
      height: 20,
      width: 20,
      margin: '20px 6px',
      selectors: {
        '&:first-child()': {
          marginLeft: 0,
        },
        '&:last-child()': {
          marginRight: 0,
        },
      },
    },
    copyright: {
      margin: '0px 16px',
      selectors: {
        '@media only screen and (max-width: 640px)': {
          marginBottom: 20,
        },
      },
    },
    links: {
      height: 14,
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      fontSize: FontSizes.mini,
      lineHeight: 14,
      color: '#000000',
    },
    appDetails: {
      fontSize: FontSizes.mini,
      color: '#A2AAAD',
      lineHeight: '14px',
      position: 'absolute',
      right: 24,
      top: 16,
    },
    subComponentStyles: {
      link: {
        root: {
          fontSize: FontSizes.mini,
          lineHeight: 14,
          color: '#005776',
          selectors: {
            ':visited': {
              color: '#005776',
            },
          },
        },
      },
      divider: {
        wrapper: {
          width: 14,
          height: 11,
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  };
};
