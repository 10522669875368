/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100vw;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

const Top = styled.div``;

const Left = styled.div`
  position: relative;
  border-right: 1px solid #ccc;
  min-width: 260px;
  height: 100%;
`;

const Margin5 = styled.div`
  margin: 5px;
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 245px;
`;

const Gallery = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Spin = styled.span`
  & > * {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

const CommandContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  border-bottom: '1px solid #F1F1F1';
  width: 100%;
`;

const CellAction = styled.div`
  display: none;
  position: absolute;
  margin-left: 100px;
  z-index: 1;
  > .ms-Button--icon:not(:last-child) {
    margin-right: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  &:hover ${CellAction} {
    display: inline-flex;
  }
`;

const CellContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  > .CellIcon {
    display: flex;
    margin-right: 5px;
  }
  ${({ depth }) => depth && `margin-left: ${depth * 16}px`}
`;

const LoaderContainer = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: #fff;
  display: flex;
`;

export { Content, Cell, CellAction, CellContent, Row, Left, Main, Spin, Top, Wrap, Gallery, Margin5, CommandContainer, LoaderContainer };
