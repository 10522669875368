/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import AppFrame from './materials-ui/amdc-app-frame/App';

import Materials from './materials-ui/App';

function isEnabled(value) {
  return value === undefined || value === 'true';
}

const appNames = [];
const enableUsercentrics = true;

const materialsHelpUrl = process.env.REACT_APP_MATERIALS_HELP_URL;

const materialsEnabled = isEnabled(process.env.REACT_APP_MATERIALDB_ENABLED);
if (materialsEnabled) {
  appNames.push('Materials');
}

const notificationEnabled = true;

class App extends Component {
  render() {
    return (
      <AppFrame defaultAppName={'Materials'} appNames={appNames} config={{ notificationEnabled, appVersion: 'v2022', enableUsercentrics }}>
        {materialsEnabled && <Materials appName="Materials" appVersion="4.0.0" onlineHelpUrl={materialsHelpUrl} />}
      </AppFrame>
    );
  }
}

export default App;
