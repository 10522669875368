import React, { useCallback } from 'react';
import { Checkbox } from '@fluentui/react';

function AdvancedSearchCheckbox({ child, getComponentKey, getCheckboxValue, onCheckboxChange }) {
  const onChange = useCallback(
    (_event, value) => {
      onCheckboxChange(child, value, child.type);
    },
    [onCheckboxChange, child]
  );

  return <Checkbox key={getComponentKey(child, 'checkbox')} label={child.name} checked={getCheckboxValue(child)} onChange={onChange} />;
}

export default AdvancedSearchCheckbox;
