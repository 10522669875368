import React from 'react';
import { ScrollablePane, SearchBox, Panel, PanelType, classNamesFunction } from '@fluentui/react';
import { Left, Margin5 } from '../../pages/styles';
import * as TextMapping from '../../utils/textMapping';
import FilterControl from '../FilterControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { useWindowSize } from 'react-use';
import { getStyles } from './styles';

const NOOP = () => {};
const getClassNames = classNamesFunction();

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function LeftSidePanel({
  roots,
  getMaterials,
  entityView,
  history,
  updateQuickSearch,
  quickSearchValue,
  search,
  qs,
  resultSummaries,
  filteredTags,
  sortColumn,
  sortDirection,
  searchFilters,
  isPanelOpen = false,
  onPanelDismiss = NOOP,
  scrollableTarget,
  favoritedClick,
}) {
  const { appContent, texts, counts } = roots;
  const { width } = useWindowSize();
  const isMobile = width < 640;

  const classNames = getClassNames(getStyles({ isMobile }));

  const Layout = (
    <Left>
      <ScrollablePane data-testid="left-side-panel" styles={{ root: { background: '#FFFFFF' } }}>
        <Margin5>
          <SearchBox
            data-testid="material-quick-search"
            label="Search"
            styles={{
              root: {
                height: '24px',
                fontSize: '11px',
                borderRadius: '12px',
              },
              iconContainer: {
                maxWidth: '20px',
              },
              icon: {
                color: '#000000',
                width: '20px',
                height: '20px',
              },
            }}
            onChange={updateQuickSearch}
            placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH_MATERIALS, texts)}
            value={quickSearchValue}
            onSearch={() => {
              qs.current && qs.current.pattern ? search(qs.current.pattern + ',' + quickSearchValue) : search(quickSearchValue);
            }}
            onClear={() => {
              search('');
            }}
            defaultValue={qs.current.pattern}
            disableAnimation={true}
          />
        </Margin5>
        {resultSummaries}
        <Margin5>
          <FilterControl
            qs={qs}
            appContent={appContent}
            history={history}
            tags={filteredTags}
            counts={counts}
            getApps={getMaterials}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            view={entityView}
            updateLoadIndex
            updateOffset
            cleanEntityItems
            searchFilters={searchFilters}
            texts={texts}
            scrollableTarget={scrollableTarget}
            favoritedClick={favoritedClick}
          />
        </Margin5>
      </ScrollablePane>
    </Left>
  );

  if (!isMobile) {
    return Layout;
  }
  return (
    <Panel
      isHiddenOnDismiss
      isLightDismiss
      isOpen={isPanelOpen}
      onDismiss={onPanelDismiss}
      styles={classNames.subComponentStyles.panel()}
      type={PanelType.customNear}
      customWidth={350}
    >
      {Layout}
    </Panel>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidePanel);
