import React, { useCallback, useEffect, useState, createRef, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, PrimaryButton, CommandButton, DefaultButton } from '@fluentui/react';
import * as useMasks from '../../common/useMasks';
import * as TextMapping from '../../utils/textMapping';
import * as actions from '../../actions';
import ImpactAssessmentRow from './ImpactAssessmentRow';
import ImpactAssessmentMachineRow from './ImpactAssessmentMachineRow';
import SaveCriteriaDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaDialog';
import LoadCriteriaDialog from '../gooey/SearchForm/Dialogs/LoadCriteriaDialog';
import SaveCriteriaAsDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaAsDialog';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { getApi } from '../../api';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';
const PlotlyPlot = createPlotlyComponent(Plotly);

const mathjs = require('mathjs');

const FORMAT = 'IA-2023-1';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function ImpactAssessment({ details, roots, downloadImpactAssessmentPDF, children, notifySearchCriteriaUpdated }) {
  const [body, setBody] = useState([]);
  const [propertyMap, setPropertyMap] = useState(new Map());
  const [machineMap, setMachineMap] = useState(new Map());
  const [total, setTotal] = useState({ gwp: 0, co2: 0 });
  const [gwpPlotData, setGwpPlotData] = useState({ data: [], layout: {} });
  const [co2PlotData, setCo2PlotData] = useState({ data: [], layout: {} });
  const [hiddengwpPlotData, setHiddenGwpPlotData] = useState({ data: [], layout: {} });
  const [hiddenco2PlotData, setHiddenCo2PlotData] = useState({ data: [], layout: {} });
  const [selectedCountry, setSelectedCountry] = useState();
  const [userDefinedAdditives, setUserDefinedAdditives] = useState([]);
  const [userDefinedMachines, setUserDefinedMachines] = useState([]);
  const [currentUUID, setCurrentUUID] = useState(null);
  const [originalCompound, setOriginalCompound] = useState(null);
  const plotParent = createRef();
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(450);
  const [CO2Footprint, setCO2Footprint] = useState();
  const [GWP, setGWP] = useState();
  const [powerConsumption, setPowerConsumption] = useState();
  const [powerMixCO2, setPowerMixCO2] = useState();
  const [powerMixGWP, setPowerMixGWP] = useState();

  // eslint-disable-next-line no-unused-vars
  const [downloadPDFClicked, setDownloadPDFClicked] = useState();
  const [downloadMessage, setDownloadMessage] = useState();
  // eslint-disable-next-line no-unused-vars
  const [modeBarButtonsToAdd, setModeBarButtonsToAdd] = useState();
  const [modeBarButtonsToRemove, setModeBarButtonsToRemove] = useState();
  const hiddenCO2Plot = useRef();
  const hiddenGWPPlot = useRef();
  const [isSaveDialogHidden, setIsSaveDialogHidden] = useState(true);
  const [isSaveAsDialogHidden, setIsSaveAsDialogHidden] = useState(true);
  const [isLoadDialogHidden, setIsLoadDialogHidden] = useState(true);
  const [currentName, setCurrentName] = useState('');
  const [currentId, setCurrentId] = useState(null);
  const [criterias, setCriterias] = useState([]);
  const [loadedDetails, setLoadedDetails] = useState();
  const [fetchedCriteriasData, setFetchedCriteriasData] = useState();

  const texts = roots.texts;
  const updateSearchCriteria = roots.updateSearchCriteria;
  const addIcon = { iconName: 'Add' };

  let api = getApi();

  const getSearchCriteria = useCallback(async () => {
    if (roots.appContent) {
      const { data } = await api.materials.getSearchCriteria(roots.appContent, FORMAT);
      let criteriasData = get(data, 'criterias');

      setFetchedCriteriasData(criteriasData);
    }
  }, [roots.appContent, api.materials]);

  useEffect(() => {
    if (roots.appContent) {
      getSearchCriteria();
    }
  }, [roots.appContent, getSearchCriteria, updateSearchCriteria]);

  useEffect(() => {
    let criteriasData;

    if (fetchedCriteriasData) {
      if (currentUUID && currentUUID !== 'blank') {
        criteriasData = fetchedCriteriasData.filter((criteria) => {
          return criteria.criterias.materialId === roots.activeId;
        });
      }
    }

    setCriterias(criteriasData);
  }, [fetchedCriteriasData, currentUUID, roots.activeId]);

  useEffect(() => {}, [criterias]);

  useEffect(() => {
    if (roots.config.plotly && roots.config.plotly.modeBarButtonsToRemove) {
      setModeBarButtonsToRemove(roots.config.plotly.modeBarButtonsToRemove);
    }
  }, [roots.config]);

  const serializeProperties = useCallback(() => {
    let serializedObject = {
      propertyMap: JSON.stringify(Array.from(propertyMap.entries())),
      machineMap: JSON.stringify(Array.from(machineMap.entries())),
      selectedCountry,
      userDefinedAdditives,
      userDefinedMachines,
      details,
    };

    return serializedObject;
  }, [propertyMap, machineMap, selectedCountry, details, userDefinedAdditives, userDefinedMachines]);

  const handleLoadCriteria = useCallback(
    async (criteria) => {
      dismissLoadDialog();

      setCurrentName(criteria.name);
      setCurrentId(criteria.id);

      if (criteria.criterias) {
        setMachineMap(new Map(JSON.parse(criteria.criterias.machineMap)));
        setPropertyMap(new Map(JSON.parse(criteria.criterias.propertyMap)));
        setUserDefinedAdditives(criteria.criterias.userDefinedAdditives);
        setUserDefinedMachines(criteria.criterias.userDefinedMachines);
        setSelectedCountry(criteria.criterias.selectedCountry);
        setLoadedDetails(criteria.criterias.details);
      }
    },
    [setMachineMap, setPropertyMap, setSelectedCountry]
  );

  const handleSaveSearchCriteria = useCallback(
    async (criteria) => {
      const searchCriteriaInput = {
        id: criteria.id,
        name: criteria.name,
        format: FORMAT,
        criterias: serializeProperties(),
      };

      if (currentUUID && currentUUID !== 'blank') {
        searchCriteriaInput.criterias.materialId = roots.activeId;
        if (roots.activeName) {
          searchCriteriaInput.name += ' (' + roots.activeName + ')';
        }
      }

      const { data } = await api.materials.saveSearchCriteria(searchCriteriaInput, roots.appContent);
      if (data.criteria && data.criteria.id) {
        setCurrentId(data.criteria.id);
      }

      notifySearchCriteriaUpdated();
    },
    [api.materials, roots.appContent, roots.activeName, serializeProperties, currentUUID, notifySearchCriteriaUpdated, roots.activeId]
  );

  function handleSaveAs(name) {
    dismissSaveAsDialog();
    setCurrentName(name);

    let criteria = { name: name, id: null };
    handleSaveSearchCriteria(criteria);
  }

  function handleSave() {
    dismissSaveDialog();

    let criteria = { name: currentName, id: currentId };
    handleSaveSearchCriteria(criteria);
  }

  const handleDeleteSearchCriteria = useCallback(
    async (_criterias) => {
      const ids = _criterias.map((c) => c.id);
      await api.materials.removeSearchCriteria(ids);

      getSearchCriteria();
      dismissLoadDialog();
    },
    [api.materials, getSearchCriteria]
  );

  const criteriaNames = useMemo(() => {
    if (criterias) {
      return criterias.map(({ name }) => name);
    } else {
      return [];
    }
  }, [criterias]);

  function dismissSaveAsDialog() {
    setIsSaveAsDialogHidden(true);
  }

  function dismissLoadDialog() {
    setIsLoadDialogHidden(true);
  }

  function dismissSaveDialog() {
    setIsSaveDialogHidden(true);
  }

  function onDownloadClick() {
    showDownloadMessage();

    let newMachineMap = new Map(machineMap);
    let newPropertyMap = new Map(propertyMap);

    let machines = [];
    let compounds = [];

    let reformattedTotal = {};
    let country = {};

    let co2spt = CO2Footprint.vkey.toString();
    let gwpspt = GWP.vkey.toString();

    if (selectedCountry && selectedCountry.key) {
      for (let [key, value] of newMachineMap) {
        value[co2spt] = { value: getMachineMapValue(key, 'co2'), unit: CO2Footprint.unit };
        value[gwpspt] = { value: getMachineMapValue(key, 'gwp'), unit: GWP.unit };
        value[powerConsumption.vkey.toString()] = value.energy;

        machines.push(value);
      }
    }

    for (let value of newPropertyMap.values()) {
      value[co2spt] = value.co2;
      value[gwpspt] = value.gwp;

      compounds.push(value);
    }

    reformattedTotal[gwpspt] = { value: total.gwp, unit: GWP.unit };
    reformattedTotal[co2spt] = { value: total.co2, unit: CO2Footprint.unit };

    if (selectedCountry) {
      let countryObject = roots.impactAssessmentData.countryMap.get(selectedCountry.key);
      country.name = countryObject.name;
      country[powerMixGWP.vkey] = countryObject[`spt-${powerMixGWP.vkey}`];
      country[powerMixCO2.vkey] = countryObject[`spt-${powerMixCO2.vkey}`];
    }

    Plotly.toImage(hiddenCO2Plot.current.el, { height: 300, width: 300, format: 'svg' })
      .then((url) => {
        return fetch(url);
      })
      .then((response) => {
        return response.text();
      })
      .then((co2svg) => {
        Plotly.toImage(hiddenGWPPlot.current.el, { height: 300, width: 300, format: 'svg' })
          .then((url) => {
            return fetch(url);
          })
          .then((response) => {
            return response.text();
          })
          .then((gwpsvg) => {
            downloadImpactAssessmentPDF(
              compounds,
              machines,
              country,
              reformattedTotal,
              hiddenco2PlotData,
              hiddengwpPlotData,
              co2svg,
              gwpsvg
            );
          });
      });
  }

  function configModeBarButtons() {
    let newModeBarButtons = [];
    if (roots && roots.config && roots.config.plotly && roots.config.plotly.modeBarButtonsToAdd) {
      for (let modeBarButton of roots.config.plotly.modeBarButtonsToAdd) {
        let newButton = {};
        if (!validateModebars(modeBarButton, 'gwp', gwpPlotData)) continue;
        else if (modeBarButton.action === 'pdf') {
          newButton.icon = modeBarButton.icon;
          newButton.name = modeBarButton.name;
          newButton.click = function () {
            showDownloadMessage();
            setDownloadPDFClicked(true);
          };
        } else {
          newButton = null;
        }

        newButton && newModeBarButtons.push(newButton);
      }
    }
    return newModeBarButtons;
  }

  //Checking for custom modebars to be added for plotly chart
  function validateModebars(modebar, chartname, plotData) {
    let permissions = roots.perms;
    //If context for specific diagram type => chartname.vkey
    let matchedVkeyAndChart = false;
    if (modebar.context && modebar.context.length > 0) {
      for (let context of modebar.context) {
        let parts = context.split('.');
        if (parts.length === 2) {
          matchedVkeyAndChart = parts[0] === chartname && parts[1] === plotData.vkey.toString();
        } else {
          matchedVkeyAndChart = parts[0] === chartname;
        }
        if (matchedVkeyAndChart) {
          break;
        }
      }
    }
    // check for user permission with modebar action permission
    let hasPermissions = !modebar.permission || modebar.permission.length === 0 ? true : false; // default
    if (!hasPermissions && modebar.permission) {
      hasPermissions = permissions.some((perm) => modebar.permission.includes(perm)) ? true : false;
    }
    //If context is * modebar is available for all chart types
    matchedVkeyAndChart = matchedVkeyAndChart || (modebar.context && modebar.context === '*');
    return matchedVkeyAndChart && hasPermissions;
  }

  useEffect(() => {
    let refNode = plotParent.current;
    const resizeObserver = new ResizeObserver((ele) => {
      setHeight(ele[0].contentRect.height);
      setWidth(ele[0].contentRect.width - 30);
    });
    if (plotParent.current) {
      resizeObserver.observe(plotParent.current);
    }
    return () => refNode && resizeObserver.unobserve(refNode);
  }, [plotParent]);

  function isNumeric(value) {
    let pattern = /^[+\-0-9eE.]*$/;
    return pattern.test(value);
  }

  function showDownloadMessage() {
    setDownloadMessage(TextMapping.getUIText(TextMapping.UI_TEXT_DOWMLOAD_MESSAGE, roots.texts));
    setTimeout(() => {
      setDownloadMessage(false);
    }, 3500);
  }

  const getMapValue = useCallback(
    (type, inputType) => {
      let mapValue = propertyMap.get(type);

      if (mapValue && mapValue[inputType]) {
        return mapValue[inputType].value;
      } else {
        return 0;
      }
    },
    [propertyMap]
  );

  const onValueChange = useCallback(
    (type, inputType, value) => {
      if (isNumeric(value)) {
        let newMap = new Map(propertyMap);

        let newProperty = newMap.get(type);

        if (inputType !== 'percentage' || (inputType === 'percentage' && Number(value) <= 100)) {
          newProperty[inputType].value = value;
          newMap.set(type, newProperty);
        }

        if (inputType === 'percentage') {
          let total = 0;
          for (let value of newMap.values()) {
            total += Number(value.percentage.value);
          }

          if (total !== 100 && newMap.get(originalCompound) && newMap.get(originalCompound).percentage) {
            let difference = total - 100;
            newMap.get(originalCompound).percentage.value = Number(newMap.get(originalCompound).percentage.value) - difference;
          }
        }

        setPropertyMap(newMap);
      }
    },
    [propertyMap, setPropertyMap, originalCompound]
  );

  const getMachineMapValue = useCallback(
    (type, inputType) => {
      let mapValue = machineMap.get(type);

      if (inputType === 'energy') {
        if (mapValue && mapValue[inputType]) {
          return mapValue[inputType].value;
        } else {
          return 0;
        }
      } else if (inputType === 'gwp' || inputType === 'co2') {
        if (
          mapValue &&
          mapValue['energy'] &&
          selectedCountry &&
          roots.impactAssessmentData &&
          roots.impactAssessmentData.countryMap &&
          roots.impactAssessmentData.countryMap.get(selectedCountry.key)
        ) {
          let multiplier = 0;
          if (inputType === 'gwp') {
            multiplier = Number(roots.impactAssessmentData.countryMap.get(selectedCountry.key)[`spt-${powerMixGWP.vkey}`].value);
          } else {
            multiplier = Number(roots.impactAssessmentData.countryMap.get(selectedCountry.key)[`spt-${powerMixCO2.vkey}`].value);
          }
          return mathjs
            .format(Number(mapValue['energy'].value) * multiplier, 3)
            .replace('e+', 'E')
            .replace('e-', 'E-');
        } else {
          return 0;
        }
      }
    },
    [machineMap, selectedCountry, powerMixGWP, powerMixCO2, roots.impactAssessmentData]
  );

  const onMachineValueChange = useCallback(
    (type, inputType, value) => {
      let newMap = new Map(machineMap);

      let newProperty = newMap.get(type);

      newProperty[inputType].value = value;
      newMap.set(type, newProperty);
      setMachineMap(newMap);
    },
    [machineMap, setMachineMap]
  );

  const onCountryChange = useCallback((_event, value) => {
    setSelectedCountry(value);
  }, []);

  const onFiberChange = useCallback(
    (_event, value) => {
      if (!originalCompound && value.key) {
        setOriginalCompound(value.key);
      }

      let newPropertyMap = new Map(propertyMap);

      let foundMatch = false;

      for (let userDefinedAdditive of userDefinedAdditives) {
        if (userDefinedAdditive.uuid === value.uuid) {
          foundMatch = true;
          break;
        }
      }

      if (!foundMatch) {
        setUserDefinedAdditives((oldAdditives) => [...oldAdditives, value]);
      } else {
        setUserDefinedAdditives((oldAdditives) => {
          for (let oldAdditive of oldAdditives) {
            if (oldAdditive.uuid === value.uuid) {
              if (oldAdditive.key !== value.key) {
                let oldProperty = newPropertyMap.get(oldAdditive.key);

                if (oldProperty) {
                  let fillerData = roots.impactAssessmentData.compoundMap.get(value.type).get(value.key);

                  newPropertyMap.set(value.key, {
                    name: fillerData.name,
                    gwp: fillerData[`spt-${GWP.vkey}`],
                    co2: fillerData[`spt-${CO2Footprint.vkey}`],
                    percentage: oldProperty.percentage,
                  });

                  newPropertyMap.delete(oldAdditive.key);
                }
              }
              oldAdditive.key = value.key;
              oldAdditive.text = value.text;

              break;
            }
          }

          return [...oldAdditives];
        });
        setPropertyMap(newPropertyMap);
      }
    },
    [originalCompound, userDefinedAdditives, propertyMap, CO2Footprint, GWP, roots.impactAssessmentData.compoundMap]
  );

  /*
  const onMachineChange = useCallback((_event, value) => {
    if (value.key) {
      setUserDefinedMachines(oldAdditives => {
        for (let oldAdditive of oldAdditives) {
          if (!oldAdditive.key && oldAdditive.type === value.type) {
            oldAdditive.key = value.key;
            oldAdditive.text = value.text;

            break;
          }
        }

        return [...oldAdditives];
      });
    } else {
      setUserDefinedMachines(oldAdditives => [...oldAdditives, value]);
    }
  }, []);*/

  const onMachineChange = useCallback(
    (_event, value) => {
      let newMachineMap = new Map(machineMap);

      let foundMatch = false;

      for (let userDefinedMachine of userDefinedMachines) {
        if (userDefinedMachine.uuid === value.uuid) {
          foundMatch = true;
          break;
        }
      }

      if (!foundMatch) {
        setUserDefinedMachines((oldMachines) => [...oldMachines, value]);
      } else {
        setUserDefinedMachines((oldMachines) => {
          for (let oldMachine of oldMachines) {
            if (oldMachine.uuid === value.uuid) {
              if (oldMachine.key !== value.key) {
                let oldProperty = newMachineMap.get(oldMachine.key);

                if (oldProperty) {
                  let machineData = roots.impactAssessmentData.machineMap.get(value.type).get(value.key);

                  if (machineData) {
                    newMachineMap.set(value.key, {
                      name: machineData.name,
                      energy: machineData[`spt-${powerConsumption.vkey}`],
                    });
                  }

                  newMachineMap.delete(oldMachine.key);
                }
              }
              oldMachine.key = value.key;
              oldMachine.text = value.text;

              break;
            }
          }

          return [...oldMachines];
        });
        setMachineMap(newMachineMap);
      }
    },
    [userDefinedMachines, machineMap, powerConsumption, roots.impactAssessmentData.machineMap]
  );

  const addUserDefinedRow = useCallback(
    (key, value) => {
      onFiberChange(null, { key: null, text: null, type: key, options: value, uuid: uuidv4() });
    },
    [onFiberChange]
  );

  const addUserDefinedMachineRow = useCallback(
    (key, value) => {
      onMachineChange(null, { key: null, text: null, type: key, options: value, uuid: uuidv4() });
    },
    [onMachineChange]
  );

  const removeRow = useCallback(
    (type) => {
      setUserDefinedAdditives((oldAdditives) =>
        oldAdditives.filter((oldAdditive) => {
          return oldAdditive.key !== type;
        })
      );

      let newMap = new Map(propertyMap);
      newMap.delete(type);

      let total = 0;
      for (let value of newMap.values()) {
        total += Number(value.percentage.value);
      }

      if (total !== 100 && newMap.get(originalCompound) && newMap.get(originalCompound).percentage) {
        let difference = total - 100;
        newMap.get(originalCompound).percentage.value = Number(newMap.get(originalCompound).percentage.value) - difference;
      }

      setPropertyMap(newMap);
    },
    [originalCompound, propertyMap]
  );

  const removeMachineRow = useCallback(
    (type) => {
      setUserDefinedMachines((oldMachines) =>
        oldMachines.filter((oldMachine) => {
          return oldMachine.key !== type;
        })
      );

      let newMap = new Map(machineMap);
      newMap.delete(type);

      setMachineMap(newMap);
    },
    [machineMap]
  );

  useEffect(() => {
    if (
      details &&
      details.uuid !== currentUUID &&
      details.content &&
      details.content.length > 0 &&
      details.content[0].polymers &&
      details.content[0].polymers.length > 0
    ) {
      setPropertyMap(new Map());
      setMachineMap(new Map());
      setOriginalCompound(details.content[0].polymers[0]);
      setCurrentUUID(details.uuid);
      setModeBarButtonsToAdd(configModeBarButtons());
      setLoadedDetails(details);
    } else if (details && details === 'blank' && currentUUID !== 'blank') {
      setPropertyMap(new Map());
      setMachineMap(new Map());
      setOriginalCompound(null);
      setCurrentUUID('blank');
      setLoadedDetails(null);
      setModeBarButtonsToAdd(configModeBarButtons());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (roots.impactAssessmentData) {
      if (roots.impactAssessmentData.compoundProps) {
        for (let prop of roots.impactAssessmentData.compoundProps) {
          if (prop.usemask === useMasks.CO2_FOOTPRINT) {
            setCO2Footprint(prop);
          } else if (prop.usemask === useMasks.GWP) {
            setGWP(prop);
          }
        }
      }

      if (roots.impactAssessmentData.machineProps && roots.impactAssessmentData.machineProps.props) {
        for (let prop of roots.impactAssessmentData.machineProps.props) {
          if (prop.usemask === useMasks.POWER_CONSUMPTION) {
            setPowerConsumption(prop);
          }
        }
      }

      if (roots.impactAssessmentData.countryProps && roots.impactAssessmentData.countryProps.props) {
        for (let prop of roots.impactAssessmentData.countryProps.props) {
          if (prop.usemask === useMasks.POWER_MIX_CO2) {
            setPowerMixCO2(prop);
          } else if (prop.usemask === useMasks.POWER_MIX_GWP) {
            setPowerMixGWP(prop);
          }
        }
      }
    }
  }, [roots.impactAssessmentData]);

  useEffect(() => {
    if (roots.impactAssessmentData && CO2Footprint && GWP && powerConsumption && powerMixCO2 && powerMixGWP) {
      let newBody = [];
      let countryOptions = [];
      let machineOptions = [];
      let compoundOptionMap = new Map();
      let machineOptionMap = new Map();

      for (const [key, value] of roots.impactAssessmentData.countryMap) {
        countryOptions.push({ key: key, text: value.name });
      }

      for (const [key, value] of roots.impactAssessmentData.machineMap) {
        if (!machineMap.get(key)) {
          machineOptions.push({ key: key, text: value.name });
        }
      }

      for (const [key, value] of roots.impactAssessmentData.machineMap) {
        let options = [];
        for (const [innerKey, innerValue] of value) {
          if (!machineMap.get(innerKey)) {
            options.push({ key: innerKey, text: innerValue.name, type: key });
          }
        }
        machineOptionMap.set(key, options);
      }

      for (const [key, value] of roots.impactAssessmentData.compoundMap) {
        let options = [];
        for (const [innerKey, innerValue] of value) {
          if (!propertyMap.get(innerKey)) {
            options.push({ key: innerKey, text: innerValue.name, type: key });
          }
        }
        compoundOptionMap.set(key, options);
      }

      newBody.push(
        <div style={{ display: 'flex', padding: '10px', gap: '5px', margin: '5px', borderBottom: '2px solid black' }}>
          <div style={{ width: '40%', fontWeight: 'bold' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_COMPOUND, texts)}</div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_PERCENTAGE_BY_WEIGHT, texts)}</div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {GWP.name}
            <br />({GWP.unit})
          </div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {CO2Footprint.name}
            <br />({CO2Footprint.unit})
          </div>
        </div>
      );

      let newMap = new Map(propertyMap);

      if (loadedDetails && loadedDetails.content) {
        for (let detail of loadedDetails.content) {
          if (detail.polymers && detail.polymers.length) {
            for (let polymer of detail.polymers) {
              for (const [key, value] of roots.impactAssessmentData.compoundMap) {
                let polymerData = value.get(polymer);

                if (polymerData) {
                  if (!newMap.get(polymer)) {
                    newMap.set(polymer, {
                      name: polymerData.name,
                      gwp: polymerData[`spt-${GWP.vkey}`],
                      co2: polymerData[`spt-${CO2Footprint.vkey}`],
                      percentage: { value: detail.fillercontent ? 100 - detail.fillercontent : 100 },
                    });
                  }

                  newBody.push(
                    <ImpactAssessmentRow
                      texts={texts}
                      type={polymer}
                      data={polymerData}
                      name={key}
                      getMapValue={getMapValue}
                      onValueChange={onValueChange}
                    />
                  );

                  break;
                }
              }
            }
          }

          if (detail.fillers && detail.fillers.length) {
            for (let filler of detail.fillers) {
              for (const [key, value] of roots.impactAssessmentData.compoundMap) {
                let fillerData = value.get(filler);
                if (fillerData) {
                  if (!newMap.get(filler)) {
                    newMap.set(filler, {
                      name: fillerData.name,
                      gwp: fillerData[`spt-${GWP.vkey}`],
                      co2: fillerData[`spt-${CO2Footprint.vkey}`],
                      percentage: { value: detail.fillercontent ? detail.fillercontent : 0 },
                    });
                  }

                  newBody.push(
                    <ImpactAssessmentRow
                      texts={texts}
                      type={filler}
                      data={fillerData}
                      name={key}
                      getMapValue={getMapValue}
                      onValueChange={onValueChange}
                    />
                  );
                }
              }
            }
          }
        }
      }

      let additiveMap = new Map();

      let additions = false;
      for (let userDefinedAdditive of userDefinedAdditives) {
        let filler = userDefinedAdditive.key;
        let fillerData = null;

        if (roots.impactAssessmentData.compoundMap && roots.impactAssessmentData.compoundMap.get(userDefinedAdditive.type)) {
          fillerData = roots.impactAssessmentData.compoundMap.get(userDefinedAdditive.type).get(filler);
        }

        if (fillerData) {
          if (!newMap.get(filler)) {
            additions = true;
            let percentage = 0;
            if (originalCompound === filler) {
              percentage = 100;
            }
            newMap.set(filler, {
              name: fillerData.name,
              gwp: fillerData[`spt-${GWP.vkey}`],
              co2: fillerData[`spt-${CO2Footprint.vkey}`],
              percentage: { value: percentage },
            });
          }
        }

        let newRow = (
          <ImpactAssessmentRow
            type={filler}
            data={fillerData}
            name={userDefinedAdditive.type}
            getMapValue={getMapValue}
            onValueChange={onValueChange}
            canRemove={true}
            removeRow={removeRow}
            dropdownOptions={userDefinedAdditive.options}
            uuid={userDefinedAdditive.uuid}
            onDropdownChange={onFiberChange}
            texts={texts}
          />
        );

        if (!additiveMap.has(userDefinedAdditive.type)) {
          additiveMap.set(userDefinedAdditive.type, [newRow]);
        } else {
          additiveMap.set(userDefinedAdditive.type, [...additiveMap.get(userDefinedAdditive.type), newRow]);
        }
        /*newBody.push(
          
        );*/
      }

      if ((propertyMap.size === 0 && newMap.size !== 0) || additions) {
        setPropertyMap(newMap);
      }

      for (const [key, value] of compoundOptionMap) {
        newBody.push(
          <CommandButton disabled={value.length === 0} iconProps={addIcon} onClick={() => addUserDefinedRow(key, value)}>
            {TextMapping.getUIText(TextMapping.UI_TEXT_ADD_TYPE, texts, { type: key })}
          </CommandButton>
        );

        newBody.push(<br />);

        if (additiveMap.has(key)) {
          newBody.push(additiveMap.get(key));
        }
      }

      newBody.push(
        <div style={{ display: 'flex', padding: '10px', gap: '5px', margin: '5px', borderBottom: '2px solid black' }}>
          <div style={{ width: '40%', fontWeight: 'bold' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_MACHINE_TOOL, texts)}</div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {powerConsumption.name}
            <br />({powerConsumption.unit})
            <br />
            <Dropdown
              options={countryOptions}
              placeholder={
                roots.impactAssessmentData && roots.impactAssessmentData.countryProps && roots.impactAssessmentData.countryProps.name
                  ? roots.impactAssessmentData.countryProps.name
                  : ''
              }
              dropdownWidth="auto"
              onChange={onCountryChange}
              disabled={userDefinedMachines.length < 1}
              defaultSelectedKey={selectedCountry ? selectedCountry.key : null}
            />
          </div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {GWP.name}
            <br />({GWP.unit})
          </div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {CO2Footprint.name}
            <br />({CO2Footprint.unit})
          </div>
        </div>
      );

      let userDefinedMachineMap = new Map();

      if (userDefinedMachines.length > 0) {
        let newMap = new Map(machineMap);

        let additions = false;
        for (let userDefinedMachine of userDefinedMachines) {
          let machine = userDefinedMachine.key;
          let machineData = null;

          if (roots.impactAssessmentData.machineMap && roots.impactAssessmentData.machineMap.get(userDefinedMachine.type)) {
            machineData = roots.impactAssessmentData.machineMap.get(userDefinedMachine.type).get(machine);
          }

          if (machineData) {
            if (!newMap.get(machine)) {
              additions = true;
              newMap.set(machine, {
                name: machineData.name,
                energy: machineData[`spt-${powerConsumption.vkey}`],
              });
            }
          }

          let newRow = (
            <ImpactAssessmentMachineRow
              type={machine}
              data={machineData}
              name={userDefinedMachine.type}
              getMapValue={getMachineMapValue}
              onValueChange={onMachineValueChange}
              canRemove={true}
              removeRow={removeMachineRow}
              dropdownOptions={userDefinedMachine.options}
              uuid={userDefinedMachine.uuid}
              onDropdownChange={onMachineChange}
              texts={texts}
            />
          );

          if (!userDefinedMachineMap.has(userDefinedMachine.type)) {
            userDefinedMachineMap.set(userDefinedMachine.type, [newRow]);
          } else {
            userDefinedMachineMap.set(userDefinedMachine.type, [...userDefinedMachineMap.get(userDefinedMachine.type), newRow]);
          }
        }

        if (additions) {
          setMachineMap(newMap);
        }
      }

      for (const [key, value] of machineOptionMap) {
        newBody.push(
          <CommandButton disabled={value.length === 0} iconProps={addIcon} onClick={() => addUserDefinedMachineRow(key, value)}>
            {TextMapping.getUIText(TextMapping.UI_TEXT_ADD_TYPE, texts, { type: key })}
          </CommandButton>
        );
        newBody.push(<br />);

        if (userDefinedMachineMap.has(key)) {
          newBody.push(userDefinedMachineMap.get(key));
        }

        /*
        newBody.push(
          <Dropdown
            key={'machinedropdown-' + key}
            options={value}
            placeholder={key}
            style={{ width: '300px', padding: '5px' }}
            onChange={onMachineChange}
            selectedKey={null}
          />
        )*/
      }

      newBody.push(
        <div style={{ display: 'flex', padding: '10px', gap: '5px', margin: '5px', borderBottom: '2px solid black' }}>
          <div style={{ width: '40%', fontWeight: 'bold' }}></div>
          <div style={{ width: '20%', fontWeight: 'bold' }}></div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {GWP.name}
            <br />({GWP.unit})
          </div>
          <div style={{ width: '20%', fontWeight: 'bold' }}>
            {CO2Footprint.name}
            <br />({CO2Footprint.unit})
          </div>
        </div>
      );

      newBody.push(
        <div style={{ display: 'flex', padding: '10px', gap: '5px', margin: '5px', border: 'rgb(227, 233, 236) solid 1px' }}>
          <div style={{ width: '40%', fontWeight: 'bold', fontSize: '14px' }}>
            {TextMapping.getUIText(TextMapping.UI_TEXT_TOTAL, texts)}
          </div>
          <div style={{ width: '20%', fontWeight: 'bold' }}></div>
          <div style={{ width: '20%', fontWeight: 'bold', fontSize: '14px' }}>{total.gwp}</div>
          <div style={{ width: '20%', fontWeight: 'bold', fontSize: '14px' }}>{total.co2}</div>
        </div>
      );

      if (gwpPlotData.data && gwpPlotData.data.length > 0) {
        newBody.push(
          <div style={{ display: 'flex', flexWrap: 'wrap', width: width }}>
            <PlotlyPlot
              style={{ width: width > 500 ? width / 2 : width, height: 300 }}
              key="gwpplot"
              useResizeHandler={true}
              data={gwpPlotData.data}
              layout={gwpPlotData.layout}
              config={{
                modeBarButtonsToRemove: modeBarButtonsToRemove,
                displaylogo: false,
                scrollZoom: true,
                showTips: true,
                doubleClick: false,
              }}
            />
            <PlotlyPlot
              style={{ width: width > 500 ? width / 2 : width, height: 300 }}
              key="co2plot"
              useResizeHandler={true}
              data={co2PlotData.data}
              layout={co2PlotData.layout}
              config={{
                modeBarButtonsToRemove: modeBarButtonsToRemove,
                displaylogo: false,
                scrollZoom: true,
                showTips: true,
                doubleClick: false,
              }}
            />
          </div>
        );

        newBody.push(
          <div style={{ height: '100%', position: 'absolute', display: 'none', pointerEvents: 'none' }}>
            <PlotlyPlot
              style={{ width: 300, height: 300 }}
              useResizeHandler={false}
              key="hiddengwpplot"
              data={hiddengwpPlotData.data}
              layout={hiddengwpPlotData.layout}
              ref={hiddenCO2Plot}
              config={{
                displaylogo: false,
                scrollZoom: true,
                showTips: true,
                doubleClick: false,
              }}
            />
            <PlotlyPlot
              style={{ width: 300, height: 300 }}
              useResizeHandler={false}
              key="hiddenco2plot"
              data={hiddenco2PlotData.data}
              layout={hiddenco2PlotData.layout}
              ref={hiddenGWPPlot}
              config={{
                displaylogo: false,
                scrollZoom: true,
                showTips: true,
                doubleClick: false,
              }}
            />
          </div>
        );
      }

      setBody(newBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    roots.impactAssessmentData,
    loadedDetails,
    setMachineMap,
    setPropertyMap,
    propertyMap,
    machineMap,
    total,
    userDefinedAdditives,
    userDefinedMachines,
    selectedCountry,
    gwpPlotData,
    co2PlotData,
    hiddengwpPlotData,
    hiddenco2PlotData,
    width,
    height,
    CO2Footprint,
    GWP,
    powerConsumption,
    powerMixCO2,
    powerMixGWP,
  ]);

  useEffect(() => {
    if (GWP && CO2Footprint && powerMixCO2 && powerMixGWP) {
      let newTotal = { co2: 0, gwp: 0 };
      let gwpValues = [];
      let co2Values = [];
      let labels = [];

      for (let value of propertyMap.values()) {
        if (value.percentage.value > 0) {
          let gwpValue = (Number(value.gwp.value) * Number(value.percentage.value)) / 100;
          let co2Value = (Number(value.co2.value) * Number(value.percentage.value)) / 100;

          labels.push(value.name);
          gwpValues.push(gwpValue);
          co2Values.push(co2Value);

          newTotal.co2 += co2Value;
          newTotal.gwp += gwpValue;
        }
      }

      for (let value of machineMap.values()) {
        if (
          selectedCountry &&
          roots.impactAssessmentData &&
          roots.impactAssessmentData.countryMap &&
          roots.impactAssessmentData.countryMap.get(selectedCountry.key)
        ) {
          let co2Value =
            Number(value.energy.value) *
            Number(roots.impactAssessmentData.countryMap.get(selectedCountry.key)[`spt-${powerMixCO2.vkey}`].value);
          let gwpValue =
            Number(value.energy.value) *
            Number(roots.impactAssessmentData.countryMap.get(selectedCountry.key)[`spt-${powerMixGWP.vkey}`].value);

          labels.push(value.name);
          gwpValues.push(gwpValue);
          co2Values.push(co2Value);
          newTotal.co2 += co2Value;
          newTotal.gwp += gwpValue;
        }
      }

      newTotal.co2 = mathjs.format(newTotal.co2, 3).replace('e+', 'E').replace('e-', 'E-');

      newTotal.gwp = mathjs.format(newTotal.gwp, 3).replace('e+', 'E').replace('e-', 'E-');

      setTotal(newTotal);

      if (co2Values.length > 0) {
        setCo2PlotData({
          layout: { title: CO2Footprint.name, autosize: true, width: width > 500 ? width / 2 : width, height: 300 },
          data: [
            {
              labels: labels,
              type: 'pie',
              values: co2Values,
              hovertemplate: '%{label}<br>%{value} ' + CO2Footprint.unit + '<extra></extra>',
            },
          ],
        });
        setHiddenCo2PlotData({
          layout: { title: CO2Footprint.name, autosize: false, width: 300, height: 300 },
          data: [
            {
              labels: labels,
              type: 'pie',
              values: co2Values,
              hovertemplate: '%{label}<br>%{value} ' + CO2Footprint.unit + '<extra></extra>',
            },
          ],
        });
      } else {
        setCo2PlotData({ data: [], layout: {} });
        setHiddenCo2PlotData({ data: [], layout: {} });
      }

      if (gwpValues.length > 0) {
        setGwpPlotData({
          layout: { title: GWP.name, autosize: true, width: width > 500 ? width / 2 : width, height: 300 },
          data: [{ labels: labels, type: 'pie', values: gwpValues, hovertemplate: '%{label}<br>%{value} ' + GWP.unit + '<extra></extra>' }],
        });
        setHiddenGwpPlotData({
          layout: { title: GWP.name, autosize: false, width: 300, height: 300 },
          data: [{ labels: labels, type: 'pie', values: gwpValues, hovertemplate: '%{label}<br>%{value} ' + GWP.unit + '<extra></extra>' }],
        });
      } else {
        setGwpPlotData({ data: [], layout: {} });
        setHiddenCo2PlotData({ data: [], layout: {} });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyMap, machineMap, selectedCountry, setTotal, width, GWP, CO2Footprint, powerMixCO2, powerMixGWP]);

  return (
    <div ref={plotParent} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div data-testid="ia-scrollable-div" style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}>
        {downloadMessage && (
          <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', zIndex: '1' }}>
            <MessageBar messageBarType={MessageBarType.info}> {downloadMessage} </MessageBar>
          </div>
        )}
        <div className="Action" style={{ display: 'flex', columnGap: '5px', padding: '5px', justifyContent: 'right' }}>
          <DefaultButton
            data-testid="search-form-save"
            //disabled={state.isSaveDisabled}
            iconProps={{ iconName: 'Save' }}
            onClick={() => setIsSaveDialogHidden(false)}
            disabled={currentId == null || currentName === ''}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE, texts)}
          />
          <DefaultButton
            data-testid="search-form-save-as"
            //disabled={state.isSaveAsDisabled}
            iconProps={{ iconName: 'SaveAs' }}
            onClick={() => setIsSaveAsDialogHidden(false)}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_AS, texts)}
          />
          <DefaultButton
            data-testid="search-form-load-criteria"
            //disabled={criterias.length === 0}
            iconProps={{ iconName: 'UploadAlt' }}
            onClick={() => {
              setIsLoadDialogHidden(false);
            }}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_GWP, texts)}
          />
        </div>
        {children}
        {body}
      </div>
      <div style={{ marginTop: '10px' }}>
        <PrimaryButton
          disabled={isEmpty(propertyMap) && isEmpty(machineMap)}
          iconProps={{ iconName: 'Download' }}
          onClick={onDownloadClick}
        >
          Download
        </PrimaryButton>
      </div>
      <LoadCriteriaDialog
        criterias={criterias}
        hidden={isLoadDialogHidden}
        onDelete={handleDeleteSearchCriteria}
        onDismiss={() => setIsLoadDialogHidden(true)}
        onLoad={handleLoadCriteria}
        texts={texts}
        title={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_GWP, texts)}
      />
      <SaveCriteriaAsDialog
        criteriaNames={criteriaNames}
        hidden={isSaveAsDialogHidden}
        onDismiss={() => setIsSaveAsDialogHidden(true)}
        onSave={(name) => handleSaveAs(name)}
        texts={texts}
        title={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_GWP_AS, texts)}
      />
      <SaveCriteriaDialog
        title={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_GWP, texts)}
        hidden={isSaveDialogHidden}
        onDismiss={() => setIsSaveDialogHidden(true)}
        onSave={handleSave}
        texts={texts}
        message={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_GWP_MESSAGE, texts)}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpactAssessment);
