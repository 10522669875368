import { Checkbox, DefaultButton, IconButton, Pivot, PivotItem, SearchBox } from '@fluentui/react';
import { useCallback, useEffect, useState, createRef } from 'react';
import { Margin5 } from '../../pages/styles';
import * as TextMapping from '../../utils/textMapping';
import React from 'react';
import PolarChart from '../PolarChart';
import { debounce } from 'lodash';

function PropertySelectionTree({
  polarCatalog,
  preferences,
  texts,
  selectedEntities,
  getPolarData,
  polarData,
  fullScreen,
  polarLoadingStatus,
}) {
  const [searchString, setSearchString] = useState('');
  const [body, setBody] = useState(new Map());
  const [maxOrder, setMaxOrder] = useState(0);
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [pivotItemId, setPivotItemId] = useState('propertySelection');
  const [isMaxSelection, setIsMaxSelection] = useState(false);
  const [maxSelectNotify, setMaxSelectNotify] = useState(false);
  const [selectedProps, setSelectedProps] = useState([]);
  const [selectionDebounced, setSelectionDebounced] = useState(false);
  const polarParent = createRef();
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(450);

  function handleSearchString(ev, value) {
    if (value) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  }

  function getPropertyId(property) {
    let vkey = property.vkey ? property.vkey : property.id;
    let id = property.type + '-' + vkey;

    if (property.use_mask && property.use_mask.length > 0) {
      id += '-' + property.use_mask.join('-');
    }
    return id;
  }

  function getCheckboxValue(id) {
    return body.get(id).checked;
  }
  function onCheckboxChange(id, value) {
    let checkedPropertiesCount = Array.from(body.values()).filter((i) => i.checked).length;
    if (checkedPropertiesCount < 7 || (checkedPropertiesCount === 7 && !value)) {
      setBody(new Map(body.set(id, { ...body.get(id), checked: value, order: getOrder(id, value) })));
      setIsMaxSelection(false);
    } else {
      setIsMaxSelection(true);
    }
  }

  function getOrder(id, value) {
    let order = maxOrder + 1;

    if (value) {
      setMaxOrder(order);
      return order;
    } else {
      return 0;
    }
  }

  const mapChildren = useCallback((parentName, parentVkey, property, properties, depth, checked, selectedItems) => {
    let order = 0;

    let key = getPropertyId(property);

    properties.set(key, {
      parentVkey: parentVkey,
      parentName: parentName,
      name: property.name,
      id: property.vkey,
      checked: checked,
      order: order,
      type: property.type,
      depth: depth,
      use_mask: property.use_mask,
      condition: property.condition,
      standardName: property.standard_name,
    });

    if (property.children && property.children.length > 0) {
      for (let child of property.children) {
        let isChecked = false;
        if (selectedItems.size > 0 && selectedItems.get(getPropertyId(child)) && selectedItems.get(getPropertyId(child)).checked) {
          isChecked = true;
        }
        if (depth === 0) {
          mapChildren(property.name, property.vkey, child, properties, depth + 1, isChecked, selectedItems);
        }
      }
    }
  }, []);

  useEffect(() => {
    let properties = new Map();
    if (filteredProperties && filteredProperties.children && filteredProperties.children.length > 0) {
      for (let child of filteredProperties.children) {
        child.children && child.children.length && mapChildren('', child.vkey, child, properties, 0, false, body);
      }
    }
    setBody(properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProperties, preferences, mapChildren]);

  const resetProperties = useCallback(() => {
    function filterProperties() {
      if (polarCatalog && polarCatalog[0] && selectedEntities && selectedEntities.length) {
        let res = selectedEntities
          .map((m) => Object.keys(m.values))
          .join()
          .split(',');
        let selectedProps = [...new Set(res)];
        selectedProps = selectedProps.map((i) => Number(i));

        let allProperties = JSON.parse(JSON.stringify(polarCatalog));

        for (let props of allProperties) {
          for (let vals of props.children) {
            if (vals.use_mask.includes(221)) {
              vals.children = vals.children.map((child) => {
                if (child.type === 'Group') {
                  child.children = child.children.filter(
                    (prop) => prop.type === 'Spt' && prop.vtype === 'numeric' && selectedProps.includes(prop.vkey)
                  );
                  return child;
                }
                return [];
              });
              setFilteredProperties(vals);
            }
          }
        }
      }
    }
    if (selectedEntities.length === 0) {
      setBody(new Map());
    }
    if (sessionStorage.getItem('Polar')) {
      setBody(new Map(JSON.parse(sessionStorage.getItem('Polar'))));
    }

    filterProperties();
  }, [polarCatalog, selectedEntities]);

  useEffect(() => {
    resetProperties();
  }, [resetProperties, selectedEntities, polarCatalog]);

  useEffect(() => {
    fetchChartDetails();
    if (body.size) {
      sessionStorage.setItem('Polar', JSON.stringify([...body]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDebounced]);

  useEffect(() => {
    updatePropertySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);
  //debouncing frequent propery selections
  const updatePropertySelection = useCallback(
    debounce(() => {
      setSelectionDebounced((selectionDebounced) => !selectionDebounced);
    }, 1000),
    [selectionDebounced]
  );

  //initital case / clear all filter case
  useEffect(() => {
    if (!polarData) fetchChartDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polarData]);

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function fetchChartDetails() {
    let props = [];
    body.forEach((i) => {
      if (i.checked) {
        props.push(i.id);
      }
    });
    setSelectedProps(props);

    const materialIds = selectedEntities.map((mat) => {
      return mat._key;
    });
    if (materialIds.length && props.length >= 3) {
      let payload = { matKeys: materialIds, spts: props };
      getPolarData(payload);
    }
  }

  let bodyArray = Array.from(body.values());
  let customizeItems = [];

  if (body && bodyArray.length > 0) {
    let filteredItems = bodyArray.filter((property) => {
      return (
        searchString === '' ||
        property.name.toLowerCase().includes(searchString.toLowerCase()) ||
        property.parentName.toLowerCase().includes(searchString.toLowerCase()) ||
        (property.condition && property.condition.toLowerCase().includes(searchString.toLowerCase())) ||
        (property.standardName && property.standardName.toLowerCase().includes(searchString.toLowerCase()))
      );
    });

    if (filteredItems && filteredItems.length > 0) {
      customizeItems = filteredItems.map((property) => {
        let margin = property.depth * 10;

        if (property.type !== 'Group') {
          if (!collapsedVkeys.includes(property.parentVkey)) {
            return (
              <div
                key={'customizecheckboxwrapper' + getPropertyId(property)}
                style={{ marginLeft: margin + 30 + 'px', padding: '5px' }}
                id={getPropertyId(property)}
              >
                <Checkbox
                  key={'customizecheckbox-' + getPropertyId(property)}
                  checked={getCheckboxValue(getPropertyId(property))}
                  onChange={(event, value) => {
                    onCheckboxChange(getPropertyId(property), value);
                  }}
                  label={
                    property.name +
                    (property.condition && property.condition !== '' ? ', ' + property.condition : '') +
                    (property.standardName ? ' (' + property.standardName + ')' : '')
                  }
                />
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            <div key={'customizetoggler-' + property.id} style={{ marginLeft: margin + 'px' }}>
              <IconButton
                style={{ width: 'auto', height: 'auto' }}
                iconProps={{ iconName: collapsedVkeys.includes(property.id) ? 'RightSmall' : 'DownSmall' }}
                onClick={() => handleToggler(property.id, !collapsedVkeys.includes(property.id))}
                title="Close"
              />
              <button
                style={{ background: 'none', border: 'none', margin: 0, padding: 0 }}
                onClick={() => handleToggler(property.id, !collapsedVkeys.includes(property.id))}
              >
                {property.name}
              </button>
            </div>
          );
        }
      });
    } else {
      customizeItems.push(<div>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_COLUMNS_MATCH_FILTER, texts)}</div>);
    }
  }

  useEffect(() => {
    isMaxSelection ? setMaxSelectNotify(true) : setMaxSelectNotify(false);
  }, [isMaxSelection]);

  useEffect(() => {
    let refNode = polarParent.current;
    const resizeObserver = new ResizeObserver((ele) => {
      setHeight(ele[0].contentRect.height);
      setWidth(ele[0].contentRect.width);
    });
    if (polarParent.current) {
      resizeObserver.observe(polarParent.current);
    }
    return () => refNode && resizeObserver.unobserve(refNode);
  }, [polarParent]);

  let checkedPropertiesCount = Array.from(body.values()).filter((i) => i.checked).length;

  const clearSelections = () => {
    let newBody = new Map();
    for (let [key, value] of body.entries()) {
      let copy = Object.assign({}, value, { checked: false });
      newBody.set(key, copy);
    }

    setBody(newBody);
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Pivot
        selectedKey={pivotItemId}
        onLinkClick={(item) => {
          setPivotItemId(item.props.itemKey);
        }}
      >
        <PivotItem
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_PROPERTY_SELECTION, texts)}
          itemKey="propertySelection"
          style={{ padding: '10px' }}
        >
          {checkedPropertiesCount > 1 && (
            <>
              <DefaultButton
                text={TextMapping.getUIText(TextMapping.UI_TEXT_CLEAR_POLAR_SELECTIONS, texts, { count: checkedPropertiesCount })}
                onClick={clearSelections}
              />
              <Margin5 />
            </>
          )}
          {maxSelectNotify && (
            <div style={{ padding: '10px' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_POLAR_PRPERTIES_MESSAGE, texts)}</div>
          )}
          <div data-testid="property-selection-tree-div" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <SearchBox
              onChange={handleSearchString}
              value={searchString}
              placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH, texts)}
            />
            <Margin5 />
            <div
              data-testid="property-selection-tree-customize-div"
              style={{ height: checkedPropertiesCount > 1 ? 'calc(100vh - 287px)' : 'calc(100vh - 250px)', overflowY: 'auto' }}
            >
              {customizeItems}
            </div>
          </div>
        </PivotItem>
        <PivotItem
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_POLAR_CHART, texts)}
          itemKey="polarChart"
          style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}
        >
          <div ref={polarParent} style={{ width: '100%', height: '100%' }}>
            <PolarChart
              polarData={polarData}
              fullScreen={fullScreen}
              polarLoadingStatus={polarLoadingStatus}
              selectedProps={selectedProps}
              texts={texts}
              height={height}
              width={width}
            ></PolarChart>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
}

export default PropertySelectionTree;
