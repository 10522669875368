/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
/* eslint-disable react/no-unused-prop-types */

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FeedbackDialog } from '../FeedbackDialog';
import { common } from './propTypes';
import Header from './core';
import { WaffleMenu } from '../WaffleMenu';
import Panels from '../panels';
import { useTopbar, withTopbar } from '../../context';
import { useDeriveTopbarContextFromProps } from './hooks/useDeriveTopbarContextFromProps';

const NOOP = () => {};

const Topbar = props => {
  const { setActivePanelId = NOOP } = props;

  const [showAppsPanel, setShowAppsPanel] = useState(false);
  const [hiddenFeedbackDialog, setHiddenFeedbackDialog] = useState(true);

  const { altairOneBaseUrl, activePanelId, waffleMenuProps } = useTopbar();

  useDeriveTopbarContextFromProps(props);

  useEffect(() => {
    setActivePanelId(activePanelId);
  }, [activePanelId, setActivePanelId]);

  const handlePanelControlClick = useCallback(
    key => {
      if (key === 'MENU') {
        setShowAppsPanel(currState => !currState);

        return;
      }
      if (key === 'FEEDBACK') {
        setHiddenFeedbackDialog(false);
        return;
      }
      if (activePanelId === key) {
        setActivePanelId(null);
      } else {
        setActivePanelId(key);
      }
    },
    [activePanelId, setActivePanelId]
  );

  const handleAppsPanelDismiss = useCallback(() => {
    setShowAppsPanel(false);
  }, []);

  const handlePanelDismiss = useCallback(() => {
    setActivePanelId(null);
  }, [setActivePanelId]);

  return (
    <div style={{ fontSize: 12 }}>
      <Header onPanelControlClick={handlePanelControlClick} showAppsPanel={showAppsPanel} />
      <WaffleMenu isOpen={showAppsPanel} onDismiss={handleAppsPanelDismiss} {...waffleMenuProps} />
      <Panels activePanelId={activePanelId} onDismiss={handlePanelDismiss} />
      <FeedbackDialog altairOneBaseUrl={altairOneBaseUrl} hidden={hiddenFeedbackDialog} onDismiss={() => setHiddenFeedbackDialog(true)} />
    </div>
  );
};

Topbar.propTypes = {
  accountPanelProps: PropTypes.shape({
    onAccountSettingsClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    onlineHelpUrl: PropTypes.string,
  }),
  setActivePanelId: PropTypes.func,
  onlineHelpUrl: PropTypes.string,
  topbarProps: PropTypes.shape({
    controlProps: PropTypes.shape({
      isHidden: PropTypes.bool,
      items: common.ControlItemsProps,
    }),
    signinButtonProps: PropTypes.shape({
      isHidden: PropTypes.bool,
    }),
    signupButtonProps: PropTypes.shape({
      isHidden: PropTypes.bool,
    }),
    waffleButtonProps: PropTypes.shape({
      isHidden: PropTypes.bool,
    }),
    wordmark: PropTypes.node,
  }),
  waffleMenuProps: common.WaffleMenuProps,
};

export default withTopbar(Topbar);
