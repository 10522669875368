import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@fluentui/react';

function PropertySelectionGroup({ child, margin, getKey, collapsedVkeys, getComponentKey, handleToggler, shouldDisplay }) {
  const onClick = useCallback(() => {
    handleToggler(child, !collapsedVkeys.includes(getKey(child)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child, collapsedVkeys, handleToggler]);

  const buttonStyle = useMemo(() => {
    return { background: 'none', border: 'none', margin: 0, padding: 0 };
  }, []);

  const iconButtonStyle = useMemo(() => {
    return { width: 'auto', height: 'auto' };
  }, []);

  const iconProps = useMemo(() => {
    return { iconName: collapsedVkeys.includes(getKey(child)) ? 'RightSmall' : 'DownSmall' };
  }, [collapsedVkeys, child, getKey]);

  const divStyle = useMemo(() => {
    return {
      display: shouldDisplay ? 'flex' : 'none',
      marginLeft: margin + 'px',
      borderBottom: '1px solid #000000',
      marginTop: '15px',
      width: 'calc(100% - 20px)',
      marginBottom: '5px',
    };
  }, [shouldDisplay, margin]);

  return (
    <div key={getComponentKey(child, 'group')} style={divStyle}>
      <IconButton style={iconButtonStyle} iconProps={iconProps} onClick={onClick} title="Close" />
      <button style={buttonStyle} onClick={onClick}>
        {child.name}
      </button>
    </div>
  );
}

export default PropertySelectionGroup;
