/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

function sort(values, sortKey, isSortedDescending, collatorOptions) {
  const collator = new Intl.Collator(undefined, collatorOptions);
  return [...values].sort((a, b) => {
    let aVal = a?.[sortKey];
    let bVal = b?.[sortKey];

    if (typeof aVal === 'string') aVal = aVal.toLowerCase();
    if (typeof bVal === 'string') bVal = bVal.toLowerCase();

    if (isSortedDescending) {
      return collator.compare(bVal, aVal);
    }
    return collator.compare(aVal, bVal);
  });
}

export default sort;
