/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const getStyles = props => {
  const { active } = props;

  return {
    root: [
      'aone-WaffleButton',
      {
        height: '32px',
      },
      active && {
        opacity: 0.8,
      },
    ],
    flexContainer: ['aone-WaffleButton-flexContainer'],
    icon: [
      'aone-WaffleButton-icon',
      {
        fontSize: '12px',
        color: '#005776',
      },
    ],
  };
};
