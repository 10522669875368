/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { useTopbar, withTopbar } from '../../../topbar/main';
import { useSelector } from 'react-redux';

import { getChildAppApi } from '../../api';
import apolloClient from '../../apollo';
import history from '../../common/history';
import ChildAppWrapper from './ChildAppWrapper';
import { LoaderContainer } from './styled';
import Loading from '../Loading';

const NOOP = () => {};

function ChildApps(props) {
  const [loader, setLoader] = useState(() => ({ isLoading: false, message: undefined }));
  const { activePanelId } = useTopbar();
  const { activeAppName, children, pathname, setActivePanelId = NOOP } = props;

  const showFullPageLoader = React.useCallback((isLoading, message) => {
    const nextLoader = { isLoading, message };
    if (!isLoading) {
      nextLoader.message = undefined;
    }
    setLoader(nextLoader);
  }, []);

  const path = pathname
    .split('/')
    .slice(2)
    .join('/');

  const loggedInUser = useSelector(store => store.loggedInUser);

  return (
    <Suspense fallback={<Loading />}>
      {loader.isLoading && (
        <LoaderContainer>
          <Loading message={loader.message} />
        </LoaderContainer>
      )}
      {React.Children.map(children, child => {
        const { appName } = child.props;
        const api = getChildAppApi(appName);
        const apollo = apolloClient;

        const isActive = appName === activeAppName && loggedInUser?.status === 'LOGGED_IN' && !!api && !!api.getAuth().userId;

        const childAppProps = {
          api,
          apollo,
          appName,
          history,
          loggedInUserEntity: loggedInUser?.entity,
          panelId: activePanelId,
          path,
          replacePanel: setActivePanelId,
          showFullPageLoader,
        };

        return (
          <ChildAppWrapper
            activeAppName={activeAppName}
            appName={appName}
            childAppProps={childAppProps}
            isActive={isActive}
            loginStatus={loggedInUser?.status}
          >
            {child}
          </ChildAppWrapper>
        );
      })}
    </Suspense>
  );
}

ChildApps.propTypes = {
  activeAppName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  pathname: PropTypes.string.isRequired,
  setActivePanelId: PropTypes.func,
};

export default withTopbar(ChildApps);
