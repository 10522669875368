import { MChart } from '@altair/mchart';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import React, { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TextMapping from '../../utils/textMapping';
import * as actions from '../../actions';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

const configs = [
  {
    name: 'legend',
    value: { width: 200, pos: 'bottom' },
  },
  {
    name: 'toolbar',
    value: {
      pos: 'right',
      iconSize: 18,
      icons: {
        resizeout: false,
        resizein: false,
        resetzoom:
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' class='icon' height='1em' width='1em'%3E%3Cpath d='m250 850l-187 0-63 0 0-62 0-188 63 0 0 188 187 0 0 62z m688 0l-188 0 0-62 188 0 0-188 62 0 0 188 0 62-62 0z m-875-938l0 188-63 0 0-188 0-62 63 0 187 0 0 62-187 0z m875 188l0-188-188 0 0-62 188 0 62 0 0 62 0 188-62 0z m-125 188l-1 0-93-94-156 156 156 156 92-93 2 0 0 250-250 0 0-2 93-92-156-156-156 156 94 92 0 2-250 0 0-250 0 0 93 93 157-156-157-156-93 94 0 0 0-250 250 0 0 0-94 93 156 157 156-157-93-93 0 0 250 0 0 250z' transform='matrix(1 0 0 -1 0 850)'%3E%3C/path%3E%3C/svg%3E",
      },
    },
  },
];

function PolarChart({ polarData, fullScreen, polarLoadingStatus, selectedProps, texts, downloadPDF, roots, width, height }) {
  const polarParent = createRef();
  const svgRef = createRef();
  const [chart, setChart] = useState(null);
  const [hiddenChart, setHiddenChart] = useState(null);
  const [maxWidth, setMaxWidth] = useState('35vw');
  const [maxHeight, setMaxHeight] = useState('70vh');
  const [isDataValid, setIsDataValid] = useState(false);
  const [isToggled, setIsToggled] = useState(true);
  const [downloadMsg, setDownloadMsg] = useState(false);

  useLayoutEffect(() => {
    setChart(new MChart('polar', 'polarchart').get());
    setHiddenChart(new MChart('polar', 'polarcharthidden').get());
  }, []);

  useEffect(() => {
    if (chart && isDataValid) {
      applyConfigs(chart, configs);
      applyTexts(chart, polarData.options);
      try {
        chart.draw(polarData);
        chart.applyFinalSize(true);
      } catch (e) {
        // ignore
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, isDataValid]);

  useEffect(() => {
    if (polarData) {
      if (polarData.data.length && Object.keys(polarData.props).length >= 3 && selectedProps.length >= 3) {
        setIsDataValid(true);
      } else {
        setIsDataValid(false);
      }
    }

    if (fullScreen) {
      setMaxHeight('73vh');
      setMaxWidth('70%');
    } else {
      setMaxHeight('70vh');
      setMaxWidth('35vw');
    }
    if (chart && isDataValid) {
      applyConfigs(chart, configs);
      try {
        chart.redraw(polarData);
        applyActions(chart);
      } catch (e) {
        // ignore
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polarData, width, height, chart, fullScreen, isDataValid, selectedProps, isToggled]);

  const applyConfigs = (chart, configs) => {
    configs = configs.map((c) => {
      if (c.name === 'legend' && fullScreen) {
        c.value = { width: 200, pos: 'left' };
      }
      if (c.name === 'legend' && !fullScreen) {
        c.value = { width: 200, pos: 'bottom' };
      }
      if (c.name === 'toolbar') {
        if (validateModebar('pdf', 'spidersplot')) {
          let icons = {
            resizeout: false,
            resizein:
              "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' class='icon' height='1em' width='1em'%3E%3Cpath d='M7.503 13.002a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0v-.5H8.5a1.5 1.5 0 0 0 0-3h-.997Zm.997 2h-.497v-1H8.5a.5.5 0 1 1 0 1Zm6.498-1.5a.5.5 0 0 1 .5-.5h1.505a.5.5 0 1 1 0 1h-1.006l-.001 1.002h1.007a.5.5 0 0 1 0 1h-1.007l.002.497a.5.5 0 0 1-1 .002l-.003-.998v-.002l.003-2.002Zm-3.498-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h.498a2 2 0 0 0 0-4H11.5Zm.5 3v-2a1 1 0 0 1 0 2ZM20 20v-1.164c.591-.281 1-.884 1-1.582V12.75c0-.698-.409-1.3-1-1.582v-1.34a2 2 0 0 0-.586-1.414l-5.829-5.828a.491.491 0 0 0-.049-.04a.63.63 0 0 1-.036-.03a2.072 2.072 0 0 0-.219-.18a.652.652 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138a.56.56 0 0 1-.059-.007a.605.605 0 0 0-.082-.007H6a2 2 0 0 0-2 2v7.168c-.591.281-1 .884-1 1.582v4.504c0 .698.409 1.3 1 1.582V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2Zm-2 .5H6a.5.5 0 0 1-.5-.5v-.996h13V20a.5.5 0 0 1-.5.5Zm.5-10.5v1h-13V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5Zm-1.122-1.5H14a.5.5 0 0 1-.5-.5V4.621L17.378 8.5Zm-12.628 4h14.5a.25.25 0 0 1 .25.25v4.504a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25V12.75a.25.25 0 0 1 .25-.25Z'%3E%3C/path%3E%3C/svg%3E",
            resetzoom:
              "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' class='icon' height='1em' width='1em'%3E%3Cpath d='m250 850l-187 0-63 0 0-62 0-188 63 0 0 188 187 0 0 62z m688 0l-188 0 0-62 188 0 0-188 62 0 0 188 0 62-62 0z m-875-938l0 188-63 0 0-188 0-62 63 0 187 0 0 62-187 0z m875 188l0-188-188 0 0-62 188 0 62 0 0 62 0 188-62 0z m-125 188l-1 0-93-94-156 156 156 156 92-93 2 0 0 250-250 0 0-2 93-92-156-156-156 156 94 92 0 2-250 0 0-250 0 0 93 93 157-156-157-156-93 94 0 0 0-250 250 0 0 0-94 93 156 157 156-157-93-93 0 0 250 0 0 250z' transform='matrix(1 0 0 -1 0 850)'%3E%3C/path%3E%3C/svg%3E",
          };
          c.value = { ...c.value, icons: icons };
        }
      }
      return c;
    });
    configs.forEach((config) => {
      chart.config(config.name, config.value);
    });
  };

  //using plotly config to show/add polar chart icons/buttons
  const validateModebar = (action, context) => {
    if (roots.config && roots.config.plotly && roots.config.plotly.modeBarButtonsToAdd) {
      for (let modeBarButton of roots.config.plotly.modeBarButtonsToAdd) {
        if (modeBarButton.action === action) {
          if (context && !modeBarButton.context.includes(context)) {
            return false;
          }
          if (!roots.perms.some((perm) => modeBarButton.permission.includes(perm))) {
            return false;
          }
          return true;
        }
      }
    }
    return false;
  };

  const applyActions = (chart) => {
    chart.dispatch.on('togglezoom', function () {
      setIsToggled(!isToggled);
    });

    chart.dispatch.on('resizeclick', function () {
      showDownloadMessage();
      let hiddenDiv = document.getElementById('polarcharthidden');
      hiddenDiv.setAttribute('height', '500px');
      hiddenDiv.setAttribute('width', '600px');
      hiddenChart.config('legend', { width: 200, pos: 'bottom' });
      hiddenChart.config('toolbar', {
        pos: 'right',
        iconSize: 18,
        icons: {
          resizeout: false,
          resizein: false,
          resetzoom: false,
        },
      });
      hiddenChart.cfg.fullrangemin = isToggled;
      hiddenChart.draw(polarData);
      hiddenChart.applyFinalSize(true);
      downloadPDF('0', 'Polar', roots.appContent, 'polarchart', hiddenDiv.outerHTML);
      hiddenDiv.innerHTML = '';
    });
  };

  const applyTexts = (chart, textObj) => {
    Object.entries(textObj).forEach(([k, v]) => {
      if (k === 'maxarea' || k === 'togglescale') chart.cfg[k] = v;
    });
  };

  function showDownloadMessage() {
    setDownloadMsg(TextMapping.getUIText(TextMapping.UI_TEXT_DOWMLOAD_MESSAGE, roots.texts));
    setTimeout(() => {
      setDownloadMsg(false);
    }, 3500);
  }

  return (
    <React.Fragment>
      {polarLoadingStatus === 'LOADING' && selectedProps.length >= 3 && (
        <div
          style={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,.5)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div
        ref={polarParent}
        className="mchart"
        style={{ maxHeight: maxHeight, maxWidth: maxWidth, width: 'auto', height: 'auto', margin: 'auto' }}
      >
        {selectedProps.length < 3 ? (
          <span style={{ padding: '10px' }}> {TextMapping.getUIText(TextMapping.UI_TEXT_POLAR_CHART_MESSAGE, texts)}</span>
        ) : null}
        <div
          id="parent-container"
          style={{
            padding: '10px',
            opacity: isDataValid && polarLoadingStatus === 'SUCCESS' ? 1 : 0,
            maxHeight: maxHeight,
            maxWidth: maxWidth,
          }}
        >
          <svg
            id="polarchart"
            ref={svgRef}
            className="polarchart"
            width={fullScreen ? `${900}px` : width - 50 > 500 ? `${550}px` : width - 50 > 0 ? `${width - 50}px` : `${550}px`}
            height={height - 50 > 500 ? `${500}px` : height - 50 > 0 ? `${height - 50}px` : `${500}px`}
          ></svg>
          {isDataValid && polarData && polarData.options.scalefull && polarData.options.scalerange && (
            <div style={{ marginLeft: '20px' }}>
              <div>{isToggled ? polarData.options.scalefull : polarData.options.scalerange}</div>
              <div>{polarData.options.scalehint}</div>
            </div>
          )}
        </div>
        <div id="parent-container-hidden" style={{ padding: '10px', position: 'absolute', opacity: 0, pointerEvents: 'none' }}>
          <svg id="polarcharthidden" className="polarchart" width={`${width}px`} height={`${height}px`}></svg>
        </div>
      </div>
      {downloadMsg && (
        <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', padding: '0', zIndex: '1' }}>
          <MessageBar messageBarType={MessageBarType.info}> {downloadMsg} </MessageBar>
        </div>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PolarChart);
