/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Checkbox } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import localPropTypes from '../../propTypes';
import { ExpandableFilter } from '../styled';
import { onRenderCheckBoxLabel } from './onRenderCheckBoxLabel';

function BooleanFilter(props) {
  const { filter, onChange, checked, toggleGroup, group } = props;
  const { name, count } = filter;

  const handleChange = useCallback(
    (e, newValue) => {
      onChange({ group, filter, newValue });
    },
    [filter, group, onChange]
  );

  const iconName = filter.isCollapsed ? 'unity_box_plus' : 'unity_box_minus';
  const iconClassName =
    filter.children && filter.children.length > 0 ? ['up-prop-tbl-toggler-icon', iconName].join(' ') : 'up-prop-tbl-toggler-no-icon';

  return (
    <ExpandableFilter>
      <div style={{ width: '14px', display: 'flex', flexDirection: 'row', flexShrink: 0 }}>
        <i
          aria-label="toogle group"
          data-testid={`filter-toggle-${name}`}
          className={iconClassName}
          onClick={() => toggleGroup(group, filter)}
          onKeyPress={() => toggleGroup(group, filter)}
          role="button"
          tabIndex="0"
        />
      </div>
      <Checkbox
        className="Filter__Value"
        data-testid={`filter-checkbox-${name}`}
        checked={checked}
        label={name}
        onChange={handleChange}
        onRenderLabel={onRenderCheckBoxLabel(count)}
        styles={{ label: { alignItems: 'flex-start' }, text: { color: '#4D4D4D' } }}
      />
    </ExpandableFilter>
  );
}

BooleanFilter.propTypes = {
  checked: PropTypes.bool,
  filter: localPropTypes.filter.isRequired,
  toggleGroup: PropTypes.func,
  onChange: PropTypes.func,
};

BooleanFilter.defaultProps = {
  checked: false,
  onChange: () => {},
};

export default React.memo(BooleanFilter);
