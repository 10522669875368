/* eslint-disable react/prop-types */
/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React from 'react';
import { classNamesFunction } from '@fluentui/utilities';
import { Badge } from '../styled';

const getClassNames = classNamesFunction();

export function onRenderCheckBoxLabel(count) {
  return props => {
    const { boxSide, className, checked, disabled, label, styles, theme } = props;

    const isReversed = boxSide !== 'start';

    const classNames = getClassNames(styles, {
      theme,
      checked,
      className,
      disabled,
      reversed: isReversed,
      isUsingCustomLabelRender: false,
    });

    return (
      <span aria-hidden="true" className={classNames.text}>
        {label}
        {Number.isInteger(count) && <Badge className="aone-FilterBadge">({count})</Badge>}
      </span>
    );
  };
}
