/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOktaAuthClient, initAuthConfig, oktaUserNameByEmail } from '../../../api/package/auth';
import { whoAmI } from '../../../api/package/user';
import { guestLogin, loginWithUserDetails } from '../../../actions';
import config from '../../../api/package/config';
import Cookies from 'universal-cookie';

export const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please refresh the page to try again or contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a> if the problem persists.</p>
</div>
`;

function useOktaSSO({ loginWithAccessToken, redirectUri: propsRedirectUri, setUserProfile, skipToOkta }) {
  const { dispatch } = useStore();
  const history = useHistory();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [status, setStatus] = useState();

  const [user, setUser] = useState();
  const [isEulaModalOpen, setIsEulaModalOpen] = useState(false);
  const [loginFailedMessage, setLoginFailedMessage] = useState();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user?.attributes?.eulaAccepted) {
      setIsEulaModalOpen(false);
      setStatus('AUTO_LOGGED_IN');
      dispatch(loginWithUserDetails(user));
    } else {
      setIsEulaModalOpen(true);
    }
  }, [dispatch, user]);

  function onAcceptEula() {
    setIsEulaModalOpen(false);
    setStatus('AUTO_LOGGED_IN');
    dispatch(loginWithUserDetails(user));
  }

  const redirectToOktaLogin = useCallback(
    async ({ username }) => {
      setIsLoggingIn(true);

      let loginHint = username;
      try {
        const oktaLogin = await oktaUserNameByEmail({ email: username });
        if (oktaLogin) {
          loginHint = oktaLogin;
        }
      } catch {
        // TODO
      }

      const param = new URLSearchParams(window.location.search);
      param.delete('sso');

      const redirectUri =
        propsRedirectUri ||
        `${window.location.pathname}${Array.from(param.values()).length > 0 ? `?${decodeURIComponent(param.toString())}` : ''}`;
      let authClient = await getOktaAuthClient();
      if (!authClient) {
        await initAuthConfig();
        authClient = await getOktaAuthClient();
      }
      authClient.token.getWithRedirect({
        responseType: ['code', 'token'],
        scopes: ['openid', 'email', 'offline_access'],
        state: redirectUri,
        loginHint,
      });
    },
    [propsRedirectUri]
  );

  const initiateOktaSSO = useCallback(async () => {
    const authClient = await getOktaAuthClient();

    setStatus('INIT_OKTA_CLIENT');

    const byPassLogin = !window.location.pathname.toLowerCase().startsWith('/login');
    if (byPassLogin) {
      setStatus('GUEST_LOGGED_IN');
      dispatch(guestLogin());

      const cookies = new Cookies();

      const userData = cookies.get('userData');
      if (userData) {
        setUserProfile(userData);
        //setUser(userData);
      }
      return;
    }

    const params = new URLSearchParams(window.location.search);

    try {
      let accessToken = await authClient.tokenManager.get('accessToken');
      let refreshToken;

      // If access token is missing or expired
      if (!accessToken || Date.now() >= accessToken.expiresAt * 1000) {
        try {
          // This is to check if user is authenticated in other tab. Subjected to browser third party cookie policy
          // This can be use to check logout too. But some browsers are blocking third party cookies
          // https://github.com/okta/okta-auth-js#third-party-cookies

          setStatus('GET_TOKEN_WITHOUT_PROMPT');
          const token = await authClient.token.getWithoutPrompt({
            responseType: ['code', 'token'],
            scopes: ['openid', 'email', 'offline_access'],
            /**
             * Default time out for the IFrame response is 120000 which is too long.
             * Reducing it to 10000 so that if no response <Loading /> will be replaced with login page.
             */
            timeout: 10000,
          });
          const tokens = token?.tokens;
          authClient.tokenManager.add('accessToken', tokens.accessToken);
          accessToken = tokens?.accessToken;
          refreshToken = tokens?.refreshToken;
        } catch (error) {
          authClient.tokenManager.clear();
          accessToken = undefined;
          // DO NOTHING
        }
      }

      if (!accessToken) {
        if (skipToOkta) {
          redirectToOktaLogin({ username: '' });
          return;
        }

        setStatus('ENTER_CREDENTIAL');
      } else {
        // TODO: Debug autoLogin
        try {
          const userProfile = await whoAmI();
          userProfile.fromSession = true;

          setUserProfile(userProfile);
          setUser(userProfile);
        } catch (e) {
          const userProfile = await loginWithAccessToken(accessToken?.accessToken, refreshToken?.refreshToken);
          setUser(userProfile);
        } finally {
          if (skipToOkta) {
            params.delete('sso');

            history.push({
              pathname: window.location.pathname,
              search: params.toString(),
            });
          }
        }

        const tokenObj = {
          jwt: accessToken.accessToken,
          expiresAt: accessToken.expiresAt * 1000,
          clearOnLogOut: true,
        };

        config.saveJWTToStorage(tokenObj);
      }
    } catch (error) {
      if (skipToOkta) {
        redirectToOktaLogin({ username: '' });
        return;
      }

      if (byPassLogin) {
        dispatch(guestLogin());
        setStatus('GUEST_LOGGED_IN');
        return;
      }

      if ((error?.errorCode === 'login_required' && error?.accessToken) || error?.message?.userMessage === 'Jwt is expired') {
        setStatus('ENTER_CREDENTIAL');
        return;
      }

      if (!error.statusCode || (error.message && error.message.indexOf('internal server error') > -1)) {
        setLoginFailedMessage(errorHtml);
      } else {
        setLoginFailedMessage(error.message);
      }
    }
  }, [dispatch, history, loginWithAccessToken, redirectToOktaLogin, setUserProfile, skipToOkta]);

  useEffect(() => {
    initiateOktaSSO();
  }, [initiateOktaSSO]);

  return { isEulaModalOpen, isLoggingIn, loginFailedMessage, onAcceptEula, redirectToOktaLogin, status };
}

export default useOktaSSO;
