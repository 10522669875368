/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import User from '../User';

export function UserAccount(props) {
  const {
    altairAdminBaseUrl,
    altairOneBaseUrl,
    additionalServicesProps,
    disableAdditionalServices,
    onAccountSettingsClick,
    onDismiss,
    onLogoutClick,
    onlineHelpUrl,
    userProfile,
  } = props;

  return (
    <div>
      <User
        altairAdminBaseUrl={altairAdminBaseUrl}
        altairOneBaseUrl={altairOneBaseUrl}
        disableAdditionalServices={disableAdditionalServices}
        additionalServicesProps={additionalServicesProps}
        email={userProfile?.username}
        userDisplayRole={userProfile?.userDisplayRole}
        name={userProfile?.name}
        company={userProfile?.company}
        onlineHelpUrl={onlineHelpUrl}
        onDismiss={onDismiss}
        onAccountSettingsClick={onAccountSettingsClick}
        onLogoutClick={onLogoutClick}
      />
      <IconButton
        data-testid="AccountPanel-Button-Close"
        iconProps={{ iconName: 'ChromeClose' }}
        onClick={onDismiss}
        styles={{
          root: {
            position: 'absolute',
            top: '12px',
            left: 'calc(100% - 44px)',
          },
        }}
      />
    </div>
  );
}

UserAccount.propTypes = {
  onLogoutClick: PropTypes.func,
  onlineHelpUrl: PropTypes.string,
  onAccountSettingsClick: PropTypes.func,
  altairAdminBaseUrl: PropTypes.string,
  altairOneBaseUrl: PropTypes.string,
  onDismiss: PropTypes.func,
  userProfile: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  }),
};

UserAccount.defaultProps = {
  onDismiss: () => {},
};

export default UserAccount;
