/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const ButtonLink = styled.div`
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Center = styled.div`
  flex-grow: 1;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  .wordmark {
    height: 20px;
    margin-left: 10px;
    overflow: hidden;
  }
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  height: 100%;

  .item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .username {
    font-weight: 400;
  }
`;

const Wrap = styled.div`
  background-color: #ffffff;
  color: #4d4d4d;
  display: flex;
  height: 32px;
  width: 100vw;
  border-bottom: 1px solid #d2d5d7;
  flex-flow: wrap;
  flex-shrink: 0;
  /* without adding z-index some office fabric components go above header */
  z-index: 11;
`;

const DummyDivToReserveSpace = styled.div`
  min-height: 40px;
`;

const pivotStyles = {
  linkIsSelected: {
    color: 'white',
    selectors: {
      ':before': {
        borderColor: '#EFCE4C',
      },
      ':hover': {
        color: 'white',
      },
    },
  },
  link: {
    color: 'white',
    selectors: {
      ':active': {
        color: 'white',
      },
      ':hover': {
        color: 'white',
      },
    },
  },
};

const Title = styled.div`
  background: none !important;
  border: none;
  color: inherit;
  padding-left: 8px;
  font: inherit;
  font-size: 30px;
`;

const VerticalLine = styled.div`
  border-left: 1px solid #d2d5d7;
  height: 100%;
`;

const SignInButton = {
  root: {
    background: '#FA4616',
    border: `1px solid #FA4616`,
    height: '25px',
    marginLeft: '10px',
    minWidth: '70px',
  },
  rootHovered: {
    color: '#BE4728',
    borderColor: '#BE4728',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.25)',
  },
  rootPressed: {
    color: '#913D26',
    borderColor: '#913D26',
  },
};

const SignUpButton = {
  root: {
    height: '25px',
    minWidth: '70px',
  },
};

export { ButtonLink, Center, Left, Right, Wrap, DummyDivToReserveSpace, pivotStyles, Title, VerticalLine, SignInButton, SignUpButton };
