import React, { useEffect, useState } from 'react';
import { CommandButton } from '@fluentui/react';

function VerticalTabs(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (props.children && selectedTab > props.children.length) {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children]);

  let buttons = props.children.map((child, index) => {
    if (child.props.children && child.props.children.length > 0) {
      return (
        <CommandButton
          style={{ textAlign: 'left', borderBottom: selectedTab === index ? 'solid 2px' : '' }}
          onClick={() => setSelectedTab(index)}
        >
          <div dangerouslySetInnerHTML={{ __html: child.props.name }}></div>
        </CommandButton>
      );
    } else {
      return null;
    }
  });
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div className={props.format} style={{ display: 'flex', flexDirection: 'column' }}>
        {buttons}
      </div>
      {selectedTab !== null ? props.children[selectedTab] : ''}
    </div>
  );
}

export { VerticalTabs };
