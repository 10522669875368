/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import { OktaAuth } from '@okta/okta-auth-js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

async function addSession() {
  const url = `/api/auth/verify`;
  const { data } = await axios.get(url);
  return data;
}

async function getGuestJwt() {
  const url = `/api/auth/v1/guest`;
  const { data } = await axios.get(url);
  return data;
}

export async function initAuthConfig() {
  return { name: 'Okta' };
}

let oktaAuthClient;
export async function getOktaAuthClient() {
  const config = null; //await legacyApi.auth.getConfig(true);

  if (config?.name === 'Okta') {
    if (!oktaAuthClient) {
      oktaAuthClient = new OktaAuth({
        ...config.config,
        pkce: true,
        expireEarlySeconds: 120,
        tokenManager: {
          secure: true,
        },
      });
    }
    return oktaAuthClient;
  }

  return null;
}

async function logout() {
  cookies.remove('userData');
  await fetch('/api/auth/v1/logout');
}

export async function loginHelp({ category, email } = {}) {
  const response = await fetch('/api/auth/v1/help', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ category, email }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw data;
  }

  return data;
}

export async function oktaUserNameByEmail({ email }) {
  const response = await fetch(`/api/auth/v1/user/exists/byemail/${encodeURIComponent(email)}`, {
    method: 'GET',
    credentials: 'include',
  });

  const data = await response.json();
  if (!response.ok) {
    throw data;
  }

  return data?.data?.oktaLogin;
}

export default {
  addSession,
  initAuthConfig,
  getGuestJwt,
  loginHelp,
  logout,
  oktaUserNameByEmail,
};
