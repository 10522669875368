/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as FullStory from '@fullstory/browser';

import ChildApps from '../ChildApps';
import { AnnouncementBanner, Content, Wrap } from './styled';
import AutoLogin from '../AutoLogin';
import useApplications from '../Header/useApplications';
import LazyTopbar from '../Topbar/LazyTopbar';
import { useAppFrame } from '../../context/useAppFrame';

const LazyNotificationspack = React.lazy(() => import('../Notification/LazyNotificationspack'));

const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

function Main(props) {
  const { activeAppName, children, pathname, enableFullStory } = props;

  const { id: userId, status: loginStatus, username, entity: user } = useSelector(state => state.loggedInUser);

  const { data, error, isLoading } = useApplications({ appId: activeAppName, username });

  const { notificationEnabled } = useAppFrame();

  useEffect(() => {
    if (!enableFullStory || loginStatus !== 'LOGGED_IN') return;

    try {
      const mdhId = user?.attributes?.hhwuUserId;
      const displayName = user?.attributes?.name;
      FullStory.init({ orgId: 'o-1CF2AY-na1' });
      FullStory.identify(mdhId, {
        displayName,
        devMode: false,
      });
    } catch (e) {
      console.error('Failed to load full story');
    }
  }, [enableFullStory, loginStatus, user]);

  if (loginStatus !== 'LOGGED_IN') {
    return <AutoLogin />;
  }

  const appWordmark = data?.application?.attributes?.ux?.wordmark ?? undefined;
  const favicon = data?.application?.attributes?.ux?.favicon;
  let appName = data?.application?.name;

  if (error) {
    appName = 'Altair One';
  }

  return (
    <>
      <Helmet>
        {appName && <title>{activeAppName}</title>}
        {favicon && <link rel="shortcut icon" type="image/ico" href={favicon} />}
      </Helmet>

      <Wrap>
        <AnnouncementBanner />
        <LazyTopbar activeAppName={activeAppName} appWordmark={isLoading ? blankImage : appWordmark} />
        <Content>
          <ChildApps activeAppName={activeAppName} pathname={pathname}>
            {children}
          </ChildApps>
        </Content>
        {userId && userId !== 'GUEST' && notificationEnabled && (
          <React.Suspense fallback={<span style={{ display: 'none' }} />}>
            <LazyNotificationspack />
          </React.Suspense>
        )}
      </Wrap>
    </>
  );
}

Main.propTypes = {
  activeAppName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  pathname: PropTypes.string.isRequired,
  enableFullStory: PropTypes.bool,
};

export default Main;
