/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React from 'react';
import PropTypes from 'prop-types';
import { classNamesFunction } from '@fluentui/utilities';

import { IconButton, Link, VerticalDivider } from '@fluentui/react';
import AltairLogo from './assets/altair_logo.svg';
import Blog from './assets/blog.svg';
import Facebbok from './assets/facebook.svg';
import Instagram from './assets/instagram.svg';
import Linkedin from './assets/linkedin.svg';
import Twitter from './assets/twitter.svg';
import Youtube from './assets/youtube.svg';

const getClassNames = classNamesFunction();
const defaultCopyRightMessage = `&copy; ${new Date().getFullYear()} Altair Engineering, Inc. All Rights Reserved. We are currently listed on Nasdaq as ALTR.`;
const defaultAppName = 'Altair One <sup style="font-size: 4px;">TM</sup>';
export function Footer({ appName = defaultAppName, appVersion, copyRightMessage = defaultCopyRightMessage, styles, theme }) {
  const classNames = getClassNames(styles, { theme });

  return (
    <footer className={classNames.root}>
      <img className={classNames.brand} src={AltairLogo} alt="Altair Engineering" />
      <IconButton
        className={classNames.badge}
        href="https://www.facebook.com/altairengineering"
        iconProps={{
          imageProps: { src: Facebbok, height: '100%', width: '100%' },
        }}
        rel="noreferrer noopener"
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />
      <IconButton
        className={classNames.badge}
        href="https://www.instagram.com/altairengineering/"
        iconProps={{
          imageProps: { src: Instagram, height: '100%', width: '100%' },
        }}
        rel="noreferrer noopener"
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />
      <IconButton
        className={classNames.badge}
        href="https://www.linkedin.com/company/8323"
        iconProps={{
          imageProps: { src: Linkedin, height: '100%', width: '100%' },
        }}
        rel="noreferrer noopener"
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />
      <IconButton
        className={classNames.badge}
        href="https://twitter.com/altair_inc"
        iconProps={{
          imageProps: { src: Twitter, height: '100%', width: '100%' },
        }}
        rel="noreferrer noopener"
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />
      <IconButton
        className={classNames.badge}
        href="https://www.youtube.com/user/InnovationSimulation"
        iconProps={{
          imageProps: { src: Youtube, height: '100%', width: '100%' },
        }}
        rel="noreferrer noopener"
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />
      <IconButton
        className={classNames.badge}
        href="https://blog.altair.com/"
        iconProps={{
          imageProps: { src: Blog, height: '100%', width: '100%' },
        }}
        styles={{ icon: { height: 20 } }}
        target="_blank"
      />

      <div className={classNames.copyright}>
        <div className={classNames.links}>
          <Link
            href="https://www.altair.com/popup_privacy.htm"
            onClick={e => {
              e.preventDefault();
              window.open('https://www.altair.com/popup_privacy.htm', 'popup', 'left=0,top=0,width=600,height=700');
              return false;
            }}
            styles={classNames.subComponentStyles.link()}
          >
            Privacy
          </Link>
          <VerticalDivider styles={classNames.subComponentStyles.divider()} />
          <Link
            href="https://www.altair.com/popup_terms.htm"
            onClick={e => {
              e.preventDefault();
              window.open('https://www.altair.com/popup_terms.htm', 'popup', 'left=0,top=0,width=600,height=700');
              return false;
            }}
            styles={classNames.subComponentStyles.link()}
          >
            Website Terms of Use
          </Link>
          <VerticalDivider styles={classNames.subComponentStyles.divider()} />
          <Link
            href="https://www.altair.com/popup_cookie_policy.htm"
            onClick={e => {
              e.preventDefault();
              window.open('https://www.altair.com/popup_cookie_policy.htm', 'popup', 'left=0,top=0,width=600,height=700');
              return false;
            }}
            styles={classNames.subComponentStyles.link()}
          >
            Cookie Consent
          </Link>
        </div>
        <div className={classNames.text} dangerouslySetInnerHTML={{ __html: copyRightMessage }} />
      </div>

      {(appName || appVersion) && (
        <div className={classNames.appDetails}>
          {appName && <span dangerouslySetInnerHTML={{ __html: appName }} />}
          {appVersion ? ` (${appVersion})` : ''}
        </div>
      )}
    </footer>
  );
}

Footer.propTypes = {
  appName: PropTypes.string,
  appVersion: PropTypes.string,
  copyRightMessage: PropTypes.string,
  styles: PropTypes.func,
  theme: PropTypes.object,
};
