/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState } from 'react';
import { IconButton, Link, ShimmerElementType, ShimmerElementsGroup, Shimmer, Text } from '@fluentui/react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { Cell, Content, Grid, Title } from './styled';
import sort from '../../utils/sort';

const styles = {
  root: {
    lineHeight: '20px',
    marginLeft: '10px',
    display: 'table',
  },
};

const customElementsGroup = () => {
  return (
    <div style={{ margin: '30px 0px 20px' }}>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 10 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 10 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
        ]}
      />
    </div>
  );
};

let requestId = 0;

const emptyArray = [];

export function AdditionalServices({
  altairOneBaseUrl = null,
  className,
  onlineHelpUrl,
  position = 'horizontal',
  services = emptyArray,
  maxHeight = '100%',
  width = '100%',
}) {
  const [allServices, setAllServices] = useState(services);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    async function getAdditionalServices() {
      try {
        setLoading(false);

        if (altairOneBaseUrl === null) return;

        const nextRequestId = ++requestId;

        const res = await fetch(`${altairOneBaseUrl}/additional/AdditionalServices.json`, {
          credentials: 'include',
          signal: controller.signal,
        });

        if (res.status === 200) {
          const additionalServices = await res.json();
          if (nextRequestId !== requestId) {
            return;
          }
          if (onlineHelpUrl) {
            const serviceLink = additionalServices?.content
              ?.find((service) => service.serviceName === 'Resources')
              ?.serviceLinks?.find((link) => link.title === 'Online Help');
            if (serviceLink) {
              serviceLink.url = onlineHelpUrl;
            }
          }
          const nextAllServices = [...services, ...additionalServices.content].map((service, index) => ({
            ...service,
            order: service.order ?? index,
            isOpen: service.isOpen ?? true,
          }));
          setAllServices(sort(nextAllServices, 'order'));
        }
      } catch (e) {
        // error in getting the config file
      }
    }
    getAdditionalServices();

    return () => {
      controller.abort();
      requestId = -1;
    };
  }, [altairOneBaseUrl, onlineHelpUrl, services]);

  const handleVisibility = (additionalServices) => {
    const newServices = [...allServices];
    const index = allServices.findIndex((e) => e.serviceName === additionalServices.serviceName);
    newServices[index] = { ...newServices[index], isOpen: !newServices[index].isOpen };
    setAllServices(newServices);
  };

  if (loading)
    return (
      <div
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
        className={classNames('ao-AdditionalService-root', 'loading', className)}
        data-testid="AdditionalServicesLoading"
      >
        <Shimmer customElementsGroup={customElementsGroup()} />
      </div>
    );

  return (
    <Grid
      position={position}
      width={width}
      length={allServices.length}
      data-testid="AdditionalServices"
      className={classNames('ao-AdditionalService-root', className)}
    >
      {allServices &&
        allServices.map((services) => (
          <Cell
            maxHeight={maxHeight}
            key={services.serviceName}
            data-testid={`AdditionalServices-${services.serviceName}`}
            className="ao-AdditionalService-service"
          >
            <Title className="ao-AdditionalService-title">
              <Text style={{ fontSize: 16 }} className="ao-AdditionalService-titleText">
                {services.serviceName}
              </Text>
              {position === 'vertical' && (
                <IconButton
                  styles={{ root: { height: '22px', float: 'right' } }}
                  iconProps={{ iconName: services.isOpen ? 'ChevronDown' : 'ChevronUp' }}
                  onClick={() => handleVisibility(services)}
                  data-testid={`${services.serviceName}-Button-ExpandCollapse`}
                />
              )}
            </Title>
            <Content isOpen={services.isOpen} className="ao-AdditionalService-content">
              {services.serviceLinks.map((link) => (
                <Link
                  key={link.title}
                  className="ao-AdditionalService-link"
                  styles={styles}
                  href={link.url}
                  target="_blank"
                  data-testid={`${services.serviceName}-Link-${link.title}`}
                >
                  {link.title}
                </Link>
              ))}
            </Content>
          </Cell>
        ))}
    </Grid>
  );
}

AdditionalServices.propTypes = {
  altairOneBaseUrl: PropTypes.string,
  className: PropTypes.string,
  onlineHelpUrl: PropTypes.string,
  position: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.any),
  maxHeight: PropTypes.string,
  width: PropTypes.string,
};

export default AdditionalServices;
