/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { isEmpty } from 'lodash';
import initialStates from '../store/initialStates';

function loggedInUser(state = {}, action) {
  switch (action.type) {
    case 'CLEAR_LOGGED_IN_USER':
      return {
        ...initialStates.loggedInUser,
      };
    case 'REPLACE_LOGGED_IN_USER':
      if (action && action.entity && !isEmpty(action.entity)) {
        return {
          entity: action.entity,
          id: action.entity.id,
          username: action.entity.attributes.userName,
        };
      } else {
        return {};
      }
    default:
      return state;
  }
}

export default loggedInUser;
