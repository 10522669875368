/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Icon, IconButton, MessageBar, MessageBarType } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationType } from '../../utils/constants';
import { createIconProps } from '../../utils/createIconProps';
import { Root } from './styled';

const NOOP = () => {};
const getStyle = (displayType = 'none') => {
  const styles = {
    root: {
      background: '#ffffff',
      minHeight: '12px',
      fontFamily: 'inherit',
    },
    iconContainer: {
      display: 'none',
    },
    expand: {
      display: `${displayType}`,
      width: '14px',
      height: '14px',
      padding: '0px',
      margin: '0px',
      selectors: {
        '.ms-Button-icon': {
          fontSize: '14px',
          color: '#1d2e38',
        },
      },
    },

    expandSingleLine: {
      title: 'Expand',
    },

    text: {
      fontFamily: 'inherit',
      fontSize: '12px',
      color: '#1d2e38',
      textAlign: 'left',
      lineHeight: '20px',
      margin: '0px',
      minHeight: '12px',
    },
  };
  return styles;
};

function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}
export function Notification({ children, onDismiss = NOOP, type, text }) {
  const [rect, ref] = useClientRect();
  const refContainer = useRef(null);

  const [iconDisplay, setIconDisplay] = useState('none');
  const width = rect ? rect.width : 0;
  const widthContainer = refContainer.current ? refContainer.current.offsetWidth : 0;

  useEffect(() => {
    if (text && rect && refContainer.current && width > widthContainer) {
      setIconDisplay('block');
    } else setIconDisplay('none');
  }, [width, widthContainer, rect, text]);

  const styles = getStyle(iconDisplay);
  const key = text || 0;

  return (
    <Root type={type}>
      <div className="iconContainer">
        <Icon {...createIconProps(type)} />
      </div>
      <div id="refContainer" ref={refContainer} className="text">
        <div className="innerText">
          <MessageBar
            key={key}
            messageBarType={MessageBarType.blocked}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            truncated
            overflowButtonAriaLabel="See more"
            styles={styles}
          >
            <span ref={ref}>{children}</span>
          </MessageBar>
        </div>
      </div>
      <IconButton iconProps={{ iconName: 'ChromeClose' }} className="dismissal" onClick={onDismiss} />
    </Root>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  onDismiss: PropTypes.func.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf([NotificationType.INFO, NotificationType.ERROR, NotificationType.SUCCESS, NotificationType.WARNING]),
};

export default Notification;
