/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, TooltipHost } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const EMPTY = {};

function FilterButton({ text, tooltip, styles: propsStyles = EMPTY, ...restProps }) {
  const styles = useMemo(() => {
    return {
      ...propsStyles,
      root: {
        marginLeft: '10px',
        background: '#EBEBEB',
        borderRadius: '10px',
        height: '20px',
        border: 'none',
        ...propsStyles.root,
      },
      label: {
        ...propsStyles.label,
        opacity: '0.7',
        fontFamily: 'NotoSans',
        fontSize: '10px',
        color: '#000000',
        lineHeight: '14px',
      },
    };
  }, [propsStyles]);

  return (
    <div style={{ paddingTop: '2px' }}>
      <TooltipHost content={tooltip}>
        <DefaultButton styles={styles} {...restProps}>
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{text}</span>
          <i className="unity_close" style={{ opacity: '0.7', fontSize: '16px', color: '#000000', marginLeft: '5px' }} />
        </DefaultButton>
      </TooltipHost>
    </div>
  );
}

FilterButton.propTypes = {
  text: PropTypes.string,
  styles: PropTypes.shape({}),
};

export { FilterButton };
