import React, { useEffect, useState } from 'react';

import { processBuildingBlock } from '../processBuildingBlock';

function BBPageComponent({ appContent, page, bbPageForComponent, getBBPageForComponent, language }) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  useEffect(() => {
    if (appContent && page && language) {
      getBBPageForComponent(page);
    }
  }, [appContent, page, getBBPageForComponent, language]);

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.bbpage' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function addBlocksPage(body, page) {
    body.push(
      <BBPageComponent
        page={page}
        appContent={appContent}
        bbPageForComponent={bbPageForComponent}
        language={language}
        getBBPageForComponent={getBBPageForComponent}
      />
    );
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    let blocksForPage = bbPageForComponent.get(page);
    if (blocksForPage) {
      for (let buildingBlock of blocksForPage) {
        processBuildingBlock({
          buildingBlock,
          body: newBody,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          addBlocksPage,
        });
      }
    }

    setBody(newBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbPageForComponent, collapsedVkeys, layoutMap, page]);

  let className = 'amdc-block bbpage ' + page;
  return <div className={className}>{body}</div>;
}

export default BBPageComponent;
